import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  Paper,
  Typography,
  List,
  Avatar,
  Grid,
  Button,
  Switch,
  Divider,
  IconButton,
} from '@material-ui/core';
import {
  Phone as PhoneIcon,
  DirectionsCar as CarIcon,
  Refresh as RefreshIcon,
} from '@material-ui/icons';
import { Skeleton } from '@material-ui/lab';
import { useListStyles } from './DriverDetails.styles';
import { getInitials } from '../../../utils/StringUtils';
import { useFiltersByKey } from '../../../hooks/useFilterByKey/useFilterByKey';
import { DeliveryItem, DeliveryItemSkeleton } from '../DeliveryItem';
import useIsDesktop from '../../../hooks/useIsDesktop/useIsDesktop';

export const handleActiveStateChange = (e, onChange) => {
  const checked = e?.target?.checked;
  const name = e?.target?.name;
  onChange({ target: { name, value: checked ? 'active' : '' } });
};

export function DriverDetails({ details, isLoading, onRefresh }) {
  const isDesktop = useIsDesktop();
  const classes = useListStyles({ isDesktop });

  const firstName = details?.firstName;
  const lastName = details?.lastName;
  const initials = useMemo(
    () => getInitials(`${firstName} ${lastName}`),
    [firstName, lastName]
  );

  const {
    data: filteredDeliveries,
    onChange,
    filters,
  } = useFiltersByKey(details?.deliveries, { status: '' });

  return (
    <Paper className={classes.container} elevation={0}>
      {onRefresh && (
        <Box display="flex" flexDirection="row" justifyContent="flex-end">
          <IconButton
            data-test-id="fwL0Rwx5NYPARIISJYyiV"
            className={classes.refreshButton}
            size="small"
            onClick={onRefresh}
            disabled={isLoading}
            aria-label="refresh drivers"
          >
            <RefreshIcon />
          </IconButton>
        </Box>
      )}
      <Box className={classes.header}>
        <Grid container direction="row" wrap="nowrap" alignItems="center">
          <Grid item>
            {isLoading ? (
              <Skeleton variant="circle" aria-label="driver avatar skeleton">
                <Avatar className={classes.largeAvatar} />
              </Skeleton>
            ) : (
              <Avatar className={classes.largeAvatar}>{initials}</Avatar>
            )}
          </Grid>
          <Grid
            item
            container
            direction="column"
            className={classes.driverDetails}
          >
            <Typography variant="h5">
              {isLoading ? (
                <Skeleton aria-label="driver name skeleton" />
              ) : (
                <>
                  {firstName} {lastName}
                </>
              )}
            </Typography>
            <Button
              href="tel:202 555 0427"
              variant="contained"
              color="default"
              className={classes.phoneButton}
              startIcon={<PhoneIcon />}
              disableElevation
            >
              {isLoading ? (
                <Skeleton aria-label="driver phone skeleton" width={100} />
              ) : (
                details?.phone || ''
              )}
            </Button>
          </Grid>
        </Grid>
      </Box>
      <Box className={classes.subHeader}>
        <Box className={classes.orderTitle}>
          <CarIcon className={classes.orderTitleIcon} />
          <Typography className={classes.orderTitleText}>Orders</Typography>
        </Box>
        <Box className={classes.activeOnly}>
          <Switch
            value={filters.status === 'active'}
            name="status"
            color="primary"
            onChange={(e) => handleActiveStateChange(e, onChange)}
            inputProps={{ 'aria-label': 'active only switch' }}
          />
          <Typography className={classes.orderTitleText}>
            Active Only
          </Typography>
        </Box>
      </Box>
      <Divider />
      <List>
        {isLoading
          ? Array(5)
              .fill()
              .map((_, i) => <DeliveryItemSkeleton key={i} />)
          : filteredDeliveries?.map((delivery) => (
              <React.Fragment key={delivery?.id}>
                <DeliveryItem delivery={delivery} />
                <Divider />
              </React.Fragment>
            ))}
      </List>
    </Paper>
  );
}

DriverDetails.propTypes = {
  details: PropTypes.shape({
    id: PropTypes.number,
    firstName: PropTypes.string,
    lastName: PropTypes.string,
    phone: PropTypes.string,
    status: PropTypes.oneOf(['busy', 'free', 'soon']),
    earned: PropTypes.number,
    deliveries: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number,
        order_id: PropTypes.number,
        status: PropTypes.string,
        mode: PropTypes.string,
        sort: PropTypes.number,
        pickup: PropTypes.shape({
          id: PropTypes.number,
          name: PropTypes.string,
          address: PropTypes.string,
          start: PropTypes.number,
          end: PropTypes.number,
          location: PropTypes.shape({
            latitude: PropTypes.number,
            longitude: PropTypes.number,
          }),
        }),
        dropoff: PropTypes.shape({
          id: PropTypes.number,
          name: PropTypes.string,
          address: PropTypes.string,
          start: PropTypes.number,
          end: PropTypes.number,
          location: PropTypes.shape({
            latitude: PropTypes.number,
            longitude: PropTypes.number,
          }),
        }),
        earns: PropTypes.number,
      })
    ),
    currentDelivery: PropTypes.number,
    location: PropTypes.shape({
      latitude: PropTypes.number,
      longitude: PropTypes.number,
    }),
  }),
  isLoading: PropTypes.bool,
  onRefresh: PropTypes.func,
};
DriverDetails.defaultProps = {
  details: { firstName: '', lastName: '', phone: '', deliveries: [] },
  isLoading: false,
};
