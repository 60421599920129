import React from 'react';
import { Typography } from '@material-ui/core';
import PropTypes from 'prop-types';
import {
  DeliveryItem,
  DeliveryItemSkeleton,
} from '../../../layout/DeliveryItem';
import { useDeliveryItem } from '../OrderHistoryPage.hooks';

function ConnectedDeliveryItem({ id }) {
  const { delivery, isLoading, isError } = useDeliveryItem({ id });
  if (isError) return <Typography align="center">Error</Typography>;
  if (isLoading) return <DeliveryItemSkeleton />;
  if (!delivery) return null;

  return <DeliveryItem key={delivery?.id} delivery={delivery} />;
}

ConnectedDeliveryItem.propTypes = {
  id: PropTypes.number,
};

export default ConnectedDeliveryItem;
