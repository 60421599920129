import md5 from 'md5';
import { api } from './Api';

export const postLogin = async ({ email, password }) => {
  const response = await api.post('/account/authorize', {
    username: email,
    password: md5(password),
  });
  return response;
};

export const fetchRefreshToken = async (refreshToken) => {
  const response = await api.post(
    '/account/refresh',
    {},
    {
      refreshToken,
      hasRefreshToken: true,
    }
  );
  return response;
};

export const deauthorizeAccount = async (refreshToken) => {
  const response = await api.post(
    '/account/deauthorize',
    {},
    {
      refreshToken,
      hasRefreshToken: true,
    }
  );
  return response;
};

export const getMe = async () => {
  const response = await api.get('/account/profile');

  return response;
};
