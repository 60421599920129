import { useEffect } from 'react';
import { useMapContext } from '../../../context/MapContext';

export const useMapHovering = ({ hoverId }) => {
  const { setCenter, setZoom, mapRef, markers } = useMapContext();
  useEffect(() => {
    if (hoverId && markers && markers?.length > 0) {
      const hoveredMarker = markers.find(({ id }) => id === hoverId);
      const map = mapRef?.current?.leafletElement;
      if (map && hoveredMarker) {
        map.flyTo([hoveredMarker.latitude, hoveredMarker.longitude], 15, {
          duration: 0.05,
        });
      }
    }
  }, [hoverId, mapRef, markers, setCenter, setZoom]);
};
