import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
  selectRow: {
    justifyContent: 'space-between',
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(2, 0),
    '& > * + *': {
      marginLeft: '10px',
    },
  },
  selectCol: {
    width: '100%',
  },

  selectFormControl: {
    width: '100%',
  },
  inputIcon: {
    marginRight: theme.spacing(1),
  },
}));
