const { makeStyles } = require('@material-ui/core');

export const useStyles = makeStyles((theme) => ({
  mapContainer: {
    position: 'absolute',
    height: '80%',
    left: 0,
    right: 0,
    top: 0,
    zIndex: 0,
    width: '100%',
    overflow: 'hidden',
  },
  mapContainerDesktop: {
    height: '100vh',
    width: '100%',
    overflow: 'hidden',
    position: 'relative',
  },
  desktopFilterContainer: {
    position: 'absolute',
    zIndex: 500,
    left: theme.spacing(2),
  },
}));
