import { snakeToCamel } from '../utils/CaseUtils';
import {
  ActiveDriversETL,
  SelectedDriverETL,
} from '../utils/ExtractTransformLoad/ActiveDriversELT';
import {
  OrderHistoryETL,
  DriverHistoryETL,
} from '../utils/ExtractTransformLoad/OrderHistoryETL';

import { api } from './Api';

export const getActiveDrivers = async () => {
  const response = await api.get('/deliveryops/drivers');
  return ActiveDriversETL(response);
};

export const getSelectedDriver = async (driverId) => {
  const response = await api.get(`/deliveryops/drivers/${driverId}`);
  return SelectedDriverETL(response);
};

export const getOrderHistory = async ({ page = 1, dateRange }) => {
  const formattedDate = dateRange && [
    parseInt(new Date(dateRange[0])?.getTime() / 1000, 10),
    parseInt(new Date(dateRange[1])?.getTime() / 1000, 10),
  ];

  const response = await api.get('/deliveryops/orders/history', {
    page,
    ...(formattedDate
      ? { start: formattedDate[0], end: formattedDate[1] }
      : {}),
  });

  return {
    ...snakeToCamel(response),
    results: OrderHistoryETL(response.results),
  };
};

export const getDriversHistory = async ({ page = 1, dateRange }) => {
  const formattedDate = dateRange && [
    parseInt(new Date(dateRange[0])?.getTime() / 1000, 10),
    parseInt(new Date(dateRange[1])?.getTime() / 1000, 10),
  ];

  const response = await api.get('/deliveryops/drivers/history', {
    page,
    ...(formattedDate
      ? { start: formattedDate[0], end: formattedDate[1] }
      : {}),
  });
  return {
    ...snakeToCamel(response),
    results: DriverHistoryETL(response.results),
  };
};

export const getOrderDetails = async (deliveryId) => {
  const response = await api.get(`/deliveryops/orders/${deliveryId}`);
  return snakeToCamel(response);
};
