import React from 'react';
import { withStyles } from '@material-ui/core';

import { createStyles, makeStyles, useTheme } from '@material-ui/core/styles';
import MuiAccordion from '@material-ui/core/Accordion';
import MuiAccordionSummary from '@material-ui/core/AccordionSummary';
import MuiAccordionDetails from '@material-ui/core/AccordionDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { Skeleton } from '@material-ui/lab';

const useAccordionStyles = makeStyles((theme) =>
  createStyles({
    root: {
      width: '100%',
    },
    heading: {
      fontSize: theme.typography.pxToRem(15),
      fontWeight: theme.typography.fontWeightRegular,
    },
    body: {
      display: 'flex',
      flexDirection: 'column',
      padding: 0,
    },
    hideCursor: {
      '&&': { cursor: 'default !important' },
    },
  })
);

const MaterialAccordion = withStyles((theme) => ({
  root: {
    marginBottom: theme.spacing(1),
    borderWidth: 1,
    borderColor: theme.palette.grey[300],
    borderStyle: 'solid',
    borderBottomWidth: 0,

    boxShadow: 'none',
    '&&': {
      borderRadius: theme.spacing(1),
    },

    '&:before': {
      display: 'none',
    },
    '&$expanded': {
      marginBottom: theme.spacing(1),

      borderBottomWidth: 1,
    },
    '&$expanded:last-child': {
      marginBottom: theme.spacing(1),
    },
  },
  expanded: {},
}))(MuiAccordion);

const MaterialAccordionSummary = withStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.common.white,
    borderBottomWidth: 1,
    borderBottomColor: theme.palette.grey[300],
    borderBottomStyle: 'solid',

    marginBottom: -1,
    minHeight: 56,

    borderRadius: theme.spacing(1),

    '&$expanded': {
      borderBottomLeftRadius: theme.spacing(0),
      borderBottomRightRadius: theme.spacing(0),
    },

    '& > *': {
      pointerEvents: 'none',
    },
  },
  content: {
    margin: 0,
    '&$expanded': {
      margin: '0',
    },
  },
  expanded: {},
}))(MuiAccordionSummary);

const MaterialAccordionDetails = withStyles(() => ({
  root: {},
}))(MuiAccordionDetails);

export function AccordionHeader({ children, hideExpandIcon, hideCursor }) {
  const classes = useAccordionStyles();
  return (
    <MaterialAccordionSummary
      className={clsx(classes.header, {
        [classes.hideCursor]: hideCursor,
      })}
      expandIcon={
        !hideExpandIcon && (
          <ExpandMoreIcon data-testid="accordion:expand-icon" />
        )
      }
      aria-controls="panel1a-content"
      id="panel1a-header"
    >
      {children}
    </MaterialAccordionSummary>
  );
}

AccordionHeader.propTypes = {
  children: PropTypes.node,
  hideExpandIcon: PropTypes.bool,
  hideCursor: PropTypes.bool,
};

export function AccordionBody({ children }) {
  const classes = useAccordionStyles();
  return (
    <MaterialAccordionDetails className={classes.body}>
      {children}
    </MaterialAccordionDetails>
  );
}

AccordionBody.propTypes = {
  children: PropTypes.node,
};

export function Accordion({ children, ...rest }) {
  const classes = useAccordionStyles();

  return (
    <div className={classes.root}>
      <MaterialAccordion {...rest}>{children}</MaterialAccordion>
    </div>
  );
}

Accordion.propTypes = {
  children: PropTypes.node,
};

export function AccordionSkeleton() {
  const theme = useTheme();
  const classes = useAccordionStyles();

  return (
    <div className={classes.root}>
      <Skeleton
        variant="rect"
        width="100%"
        style={{
          borderRadius: theme.spacing(1),
          marginBottom: theme.spacing(1),
        }}
        aria-label="accordion skeleton"
      >
        <div style={{ width: '100%', height: 70 }} />
      </Skeleton>
    </div>
  );
}
