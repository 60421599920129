import { createStyles, makeStyles } from '@material-ui/core';

export const useDeliverySummaryStyles = makeStyles((theme) => ({
  primaryText: {
    fontWeight: 'bold',
    fontSize: 16,
  },
  secondaryContainer: {
    fontSize: 12,
    display: 'flex',
    alignItems: 'center',
  },
  metaContainer: {
    display: 'flex',
    fontSize: 12,
    color: theme.palette.grey[600],
  },
  metaText: {
    fontSize: 12,
  },
  divider: {
    marginRight: theme.spacing(1),
    marginLeft: theme.spacing(1),
    borderRadius: 1,
    width: 2,
    height: 2,
    background: '#0000008A 0% 0% no-repeat padding-box',
  },
}));

export const useDriverItemStyles = makeStyles((theme) =>
  createStyles({
    card: {
      margin: 0,
      padding: 0,
      paddingTop: theme.spacing(2),
      paddingBottom: theme.spacing(2),
    },
    cardContent: {
      padding: theme.spacing(0, 2),
      paddingBottom: '0 !important',
    },
    sectionHeader: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
    },
    sectionHeaderText: {
      fontSize: 12,
      fontWeight: 'bold',
    },
    restaurantName: {
      paddingTop: theme.spacing(0.75),
    },
    metaContainer: {
      display: 'flex',
      alignItems: 'start',
      fontSize: 12,
      color: theme.palette.grey[600],
    },
    metaText: {
      fontSize: 12,
    },
    metaIcon: {
      width: 12,
      height: 12,
      marginRight: theme.spacing(1),
      marginTop: theme.spacing(0.25),
      marginBottom: theme.spacing(0.25),
    },
    spacing: {
      paddingBottom: theme.spacing(1.5),
    },
  })
);
