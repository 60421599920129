import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { Theme } from '../../layout/Theme';
import { getInitials } from '../../../utils/StringUtils';
import './DriverMarker.scss';

function DriverMarkerBase({ status, text, isDimmed }) {
  const initials = useMemo(() => getInitials(text), [text]);

  return (
    <div className={clsx('driver-marker-container')}>
      <div
        className={clsx('marker', {
          free: status === 'free',
          soon: status === 'soon',
          busy: status === 'busy',
          dimmed: isDimmed,
        })}
      >
        {initials}
      </div>
    </div>
  );
}
DriverMarkerBase.propTypes = {
  status: PropTypes.string,
  text: PropTypes.string,
  isDimmed: PropTypes.bool,
};
DriverMarkerBase.defaultProps = {
  status: 'free',
  text: '',
};

export function DriverMarker({ status, text, isDimmed }) {
  return (
    <Theme>
      <DriverMarkerBase status={status} text={text} isDimmed={isDimmed} />
    </Theme>
  );
}

DriverMarker.propTypes = {
  status: PropTypes.string,
  text: PropTypes.string,
  isDimmed: PropTypes.bool,
};
DriverMarker.defaultProps = {
  status: 'free',
  text: '',
};
