import React, { useCallback, useEffect, useMemo } from 'react';
import { Box, Button, Typography } from '@material-ui/core';
import { Pagination } from '@material-ui/lab';
import PropTypes from 'prop-types';
import { DeliveryItemSummary } from '../../../layout/DeliveryItem';
import {
  Accordion,
  AccordionBody,
  AccordionHeader,
  AccordionSkeleton,
} from '../../../layout/Accordion';
import ConnectedDeliveryItem from './ConnectedDeliveryItem';
import { usePaginatedHistory } from '../OrderHistoryPage.hooks';

function _DeliverySortedList({ dateRange, setMetadata }) {
  const [page, setPage] = React.useState(1);

  const { orders, isFetching, isError, refetch } = usePaginatedHistory({
    page,
    dateRange,
  });

  const results = orders?.results;
  const totalPages = orders?.totalPages;

  const [expanded, setExpanded] = React.useState(false);

  const handleChange = useCallback(
    (panel) => (event, isExpanded) => {
      setExpanded(isExpanded ? panel : false);
    },
    [setExpanded]
  );

  useEffect(() => {
    if (orders)
      setMetadata({
        totalResults: orders.totalResults,
        totalEarned: orders.totalEarned,
      });
  }, [orders, setMetadata]);

  const Content = useMemo(() => {
    if (isError) {
      return (
        <Box alignItems="center" justifyContent="center" display="flex" p={2}>
          <Typography>We had a problem loading the requested items</Typography>
          <Button
            data-test-id="w2C6PzgD4z0UylxtEJtdK"
            padding={2}
            onClick={refetch}
          >
            Retry
          </Button>
        </Box>
      );
    }
    if (isFetching) {
      return Array(10)
        .fill()
        .map((_, i) => <AccordionSkeleton key={i} />);
    }
    if (results) {
      return results?.map((order) => (
        <Accordion
          data-testid="delivery-list:accordion"
          key={order.id}
          expanded={expanded === order.id}
          onChange={handleChange(order.id)}
          TransitionProps={{ unmountOnExit: true }}
        >
          <AccordionHeader>
            <DeliveryItemSummary orderSummary={order} />
          </AccordionHeader>
          <AccordionBody>
            <ConnectedDeliveryItem
              key={order.id}
              id={order.id}
              isExpanded={expanded === order.id}
            />
          </AccordionBody>
        </Accordion>
      ));
    }
    return null;
  }, [isFetching, isError, results, refetch, expanded, handleChange]);

  return (
    <>
      <Box display="flex" justifyContent="center" paddingY={2}>
        <Pagination
          count={totalPages}
          page={page}
          onChange={(_, value) => {
            setPage(value);
          }}
        />
      </Box>
      {Content}
    </>
  );
}

_DeliverySortedList.propTypes = {
  setMetadata: PropTypes.func,
  dateRange: PropTypes.arrayOf(PropTypes.any),
};

export const DeliverySortedList = React.memo(_DeliverySortedList);
