import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  Typography,
  CardContent,
  Card,
  ListItem,
  ListItemText,
} from '@material-ui/core';
import {
  Navigation as NavigationIcon,
  WatchLater as WatchIcon,
  AttachMoney as MoneyIcon,
} from '@material-ui/icons';
import { Skeleton } from '@material-ui/lab';
import clsx from 'clsx';
import {
  useDriverItemStyles,
  useDeliverySummaryStyles,
} from './DeliveryItem.styles';
import {
  formatDeliveryTime,
  formatRelativeDeliveryTime,
} from '../../../utils/DateUtils';
import { formatMoney } from '../../../utils/StringUtils';

export function DeliveryItemSkeleton() {
  const classes = useDriverItemStyles();

  return (
    <Card
      elevation={0}
      className={classes.card}
      aria-label="delivery item skeleton"
    >
      <CardContent className={classes.cardContent}>
        <Box className={classes.sectionHeader}>
          <Typography className={classes.sectionHeaderText}>
            <Skeleton width={60} />
          </Typography>
          <Typography className={classes.sectionHeaderText}>
            <Skeleton width={20} />
          </Typography>
        </Box>
        <Typography className={classes.restaurantName}>
          <Skeleton />
        </Typography>
        <Box className={classes.metaContainer}>
          <Typography className={classes.metaText}>
            <Skeleton width={100} />
          </Typography>
        </Box>
        <Box className={classes.metaContainer}>
          <WatchIcon className={classes.metaIcon} />
          <Typography className={classes.metaText}>
            <Skeleton width={100} />
          </Typography>
        </Box>
      </CardContent>
      <Box className={classes.spacing} />
      <CardContent className={classes.cardContent}>
        <Box className={classes.sectionHeader}>
          <Typography className={classes.sectionHeaderText}>
            <Skeleton width={60} />
          </Typography>
        </Box>

        <Box className={classes.metaContainer}>
          <NavigationIcon className={classes.metaIcon} />
          <Typography className={classes.metaText}>
            <Skeleton width={150} />
          </Typography>
        </Box>
        <Box className={classes.metaContainer}>
          <WatchIcon className={classes.metaIcon} />
          <Typography className={classes.metaText}>
            <Skeleton width={100} />
          </Typography>
        </Box>
        <Box className={classes.metaContainer}>
          <MoneyIcon className={classes.metaIcon} />
          <Typography className={classes.metaText}>
            <Skeleton width={100} />
          </Typography>
        </Box>
      </CardContent>
    </Card>
  );
}

export function DeliveryItemSummary({ orderSummary }) {
  const classes = useDeliverySummaryStyles();
  const now = useMemo(() => Date.now(), []);

  const id = orderSummary?.id;
  const restaurantName = orderSummary.restaurant.name;
  const driverName = `${orderSummary?.driver.firstName} ${orderSummary?.driver.lastName}`;
  const earned = formatMoney(orderSummary?.earned);

  return (
    <ListItem
      disableGutters
      className={classes.listItem}
      button
      aria-label="driver item"
      disableRipple
    >
      <ListItemText
        disableTypography
        primary={
          <Typography className={classes.primaryText}>
            #{id} {restaurantName}
          </Typography>
        }
        secondary={
          <Box
            className={clsx([
              classes.secondaryContainer,
              classes.metaContainer,
            ])}
          >
            <Typography className={classes.metaText}>{driverName}</Typography>
            <div className={classes.divider} />
            <Typography className={classes.metaText}>
              {formatRelativeDeliveryTime(orderSummary?.delivered * 1000, now)}
            </Typography>
            <div className={classes.divider} />
            <Typography className={classes.metaText}>{earned}</Typography>
          </Box>
        }
      />
    </ListItem>
  );
}

DeliveryItemSummary.propTypes = {
  orderSummary: PropTypes.shape({
    id: PropTypes.number,
    restaurant: PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string,
    }),
    driver: PropTypes.shape({
      id: PropTypes.number,
      firstName: PropTypes.string,
      lastName: PropTypes.string,
    }),
    pickedup: PropTypes.number,
    delivered: PropTypes.number,
    status: PropTypes.string,
    earned: PropTypes.number,
  }),
};

export const buildPickupText = ({ status, pickup }) => {
  const pickupStart = formatDeliveryTime(pickup?.start);
  const pickupEnd = formatDeliveryTime(pickup?.end);
  return (
    {
      delivered: `${pickupEnd} pickup time`,
      canceled: 'Delivery canceled',
    }[status] || `${pickupStart} - ${pickupEnd} pickup time`
  );
};

export const buildDropoffText = ({ status, dropoff }) => {
  const dropoffStart = formatDeliveryTime(dropoff?.start);
  const dropoffEnd = formatDeliveryTime(dropoff?.end);
  return (
    {
      delivered: `${dropoffEnd} dropoff time`,
      canceled: 'Delivery canceled',
    }[status] || `${dropoffStart} - ${dropoffEnd} target time`
  );
};

export function DeliveryItem({ delivery }) {
  const classes = useDriverItemStyles({ status: delivery.status });

  const pickup = delivery?.pickup;
  const dropoff = delivery?.dropoff;

  const orderId = delivery?.orderId;
  const restaurantName = delivery?.pickup?.name;
  const pickupAddress = pickup?.address;
  const dropoffAddress = dropoff?.address;
  const earns = delivery?.earns;
  const earned = delivery?.earned;

  const status = delivery?.status;

  const pickupText = useMemo(
    () => buildPickupText({ status, pickup }),
    [status, pickup]
  );
  const dropoffText = useMemo(
    () => buildDropoffText({ status, dropoff }),
    [status, dropoff]
  );

  const moneyText = useMemo(
    () =>
      earned
        ? `${formatMoney(earned)} earned`
        : `${formatMoney(earns)} will earn`,
    [earns, earned]
  );

  return (
    <Card elevation={0} className={classes.card} aria-label="delivery item">
      <CardContent className={classes.cardContent}>
        <Box className={classes.sectionHeader}>
          <Typography className={classes.sectionHeaderText}>Pickup</Typography>
          <Typography className={classes.sectionHeaderText}>
            #{orderId}
          </Typography>
        </Box>
        <Typography className={classes.restaurantName}>
          {restaurantName}
        </Typography>
        <Box className={classes.metaContainer}>
          <Typography className={classes.metaText}>{pickupAddress}</Typography>
        </Box>
        <Box className={classes.metaContainer}>
          <WatchIcon className={classes.metaIcon} />
          <Typography className={classes.metaText}>{pickupText}</Typography>
        </Box>
      </CardContent>
      <Box className={classes.spacing} />
      <CardContent className={classes.cardContent}>
        <Box className={classes.sectionHeader}>
          <Typography className={classes.sectionHeaderText}>Dropoff</Typography>
        </Box>

        <Box className={classes.metaContainer}>
          <NavigationIcon className={classes.metaIcon} />
          <Typography className={classes.metaText}>{dropoffAddress}</Typography>
        </Box>
        <Box className={classes.metaContainer}>
          <WatchIcon className={classes.metaIcon} />
          <Typography className={classes.metaText}>{dropoffText}</Typography>
        </Box>
        <Box className={classes.metaContainer}>
          <MoneyIcon className={classes.metaIcon} />
          <Typography className={classes.metaText}>{moneyText}</Typography>
        </Box>
      </CardContent>
    </Card>
  );
}

DeliveryItem.propTypes = {
  delivery: PropTypes.shape({
    id: PropTypes.number,
    orderId: PropTypes.number,
    status: PropTypes.string,
    mode: PropTypes.string,
    sort: PropTypes.number,
    pickup: PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string,
      address: PropTypes.string,
      start: PropTypes.number,
      end: PropTypes.number,
      location: PropTypes.shape({
        latitude: PropTypes.number,
        longitude: PropTypes.number,
      }),
    }),
    dropoff: PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string,
      address: PropTypes.string,
      start: PropTypes.number,
      end: PropTypes.number,
      location: PropTypes.shape({
        latitude: PropTypes.number,
        longitude: PropTypes.number,
      }),
    }),
    earns: PropTypes.number,
    earned: PropTypes.number,
  }),
};
