import React from 'react';
import { Box } from '@material-ui/core';
import PropTypes from 'prop-types';
import { useStyles } from './DesktopLayout.styles';
import useViewportHeight from '../../../hooks/useViewportHeight/useViewportHeight';

export function DesktopLayout({
  HeaderComponent,
  FooterComponent,
  children,
  MapComponent,
  paddingBottom,
}) {
  const classes = useStyles();
  const vh = useViewportHeight();
  return (
    <Box display="flex">
      <Box
        height={100 * vh}
        overflow="scroll"
        className={classes.desktopColumn}
      >
        <Box className={classes.desktopHeader}>{HeaderComponent}</Box>

        <Box className={classes.body}>
          {children}
          {FooterComponent && <Box height={paddingBottom} />}
        </Box>

        {FooterComponent && (
          <Box className={classes.desktopFooter}>{FooterComponent}</Box>
        )}
      </Box>
      <Box
        className={classes.mapContainerDesktop}
        data-testid="desktop-map-container"
      >
        {MapComponent}
      </Box>
    </Box>
  );
}

DesktopLayout.propTypes = {
  HeaderComponent: PropTypes.node,
  FooterComponent: PropTypes.node,
  children: PropTypes.node,
  MapComponent: PropTypes.node,
  paddingBottom: PropTypes.number,
};
