import React, { useMemo, useState } from 'react';
import clsx from 'clsx';
import { Box } from '@material-ui/core';
import { useStyles } from './OrderHistoryPage.styles';
import { LeftMenuDrawer } from '../../navigation/LeftMenuDrawer/LeftMenuDrawer';
import { SearchMenuBar } from '../../navigation/SearchMenuBar/SearchMenuBar';
import { DesktopLayout } from '../../layout/DesktopLayout/DesktopLayout';
import ActiveDriversMap from '../../layout/ActiveDriversMap/ActiveDriversMap';
import {
  useActiveDrivers,
  useActiveDriversRequest,
} from '../../../hooks/useActiveDrivers/useActiveDrivers';
import useIsDesktop from '../../../hooks/useIsDesktop/useIsDesktop';
import { DriverSortedList } from './components/DriverSortedList';
import SortFilter from './components/SortFilter';
import { DeliverySortedList } from './components/DeliverySortedList';
import { getDateRanges } from '../../../utils/DateUtils';
import OrderHistoryFooter from '../../layout/OrderHistoryFooter';

export const getDriversTitleBySortBy = (sortBy) =>
  sortBy === 'driver' ? 'Drivers' : 'Orders';

export function OrderHistoryPage() {
  const classes = useStyles();

  const isDesktop = useIsDesktop();

  const { driverList, driverListLoading, refetch } = useActiveDriversRequest();
  const { filterOptions, onFilterChange, filters } = useActiveDrivers({
    driverList,
    driverListLoading,
    refetch,
  });

  const [metadata, setMetadata] = useState({});

  const [sortBy, setSortBy] = useState('driver');
  const [dateRange, setDateRange] = useState(() => {
    const now = new Date();
    return getDateRanges(now).today;
  });

  const list = useMemo(
    () =>
      ({
        driver: (
          <DriverSortedList
            type="order-history"
            setMetadata={setMetadata}
            dateRange={dateRange}
          />
        ),
        date: (
          <DeliverySortedList setMetadata={setMetadata} dateRange={dateRange} />
        ),
      }[sortBy]),
    [sortBy, dateRange]
  );

  const driversTitle = getDriversTitleBySortBy(sortBy);

  return isDesktop ? (
    <main className={clsx(classes.content)}>
      <LeftMenuDrawer />
      <DesktopLayout
        HeaderComponent={
          <Box className={classes.desktopSearchBar}>
            <SearchMenuBar title="Order History" />
          </Box>
        }
        MapComponent={
          <ActiveDriversMap
            filterOptions={filterOptions}
            onFilterChange={onFilterChange}
            filters={filters}
            disableClusteringAtZoom={false}
          />
        }
        FooterComponent={
          <Box className={classes.desktopFooterSummary}>
            <OrderHistoryFooter
              driversTitle={driversTitle}
              drivers={metadata?.totalResults}
              earned={metadata?.totalEarned}
            />
          </Box>
        }
        paddingBottom={100}
      >
        <Box padding={1}>
          <SortFilter
            sortBy={sortBy}
            setSortBy={setSortBy}
            setDateRange={setDateRange}
          />
          {list}
        </Box>
      </DesktopLayout>
    </main>
  ) : (
    <main className={clsx(classes.content)}>
      <LeftMenuDrawer />
      <Box className={classes.header}>
        <SearchMenuBar title="Order History" />
      </Box>
      <Box className={classes.body}>
        <SortFilter
          sortBy={sortBy}
          setSortBy={setSortBy}
          setDateRange={setDateRange}
        />
        {list}
      </Box>
      <Box className={classes.footer}>
        <OrderHistoryFooter
          driversTitle={driversTitle}
          completed={metadata?.totalResults}
          earned={metadata?.totalEarned}
        />
      </Box>
    </main>
  );
}
