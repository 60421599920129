import React from 'react';
import { TextField } from '@material-ui/core';
import PropTypes from 'prop-types';

function Input({ register, name, ...rest }) {
  return <TextField name={name} inputRef={register} {...rest} />;
}

Input.propTypes = {
  register: PropTypes.any,
  name: PropTypes.string,
};

export default Input;
