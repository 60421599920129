import { createStyles, fade, makeStyles } from '@material-ui/core';

export const useListStyles = makeStyles((theme) =>
  createStyles({
    container: ({ isDesktop }) =>
      isDesktop
        ? {
            position: 'relative',
            zIndex: 3,
            flex: 1,
            height: '100%',
            borderTopLeftRadius: 25,
            borderTopRightRadius: 25,
          }
        : {
            position: 'relative',
            zIndex: 3,
          },

    root: {
      width: '100%',
      backgroundColor: theme.palette.background.paper,
    },
    header: {
      display: 'flex',
      alignItems: 'center',
      padding: theme.spacing(4, 2),
      borderTopLeftRadius: ({ isDesktop }) => isDesktop && 25,
      borderTopRightRadius: ({ isDesktop }) => isDesktop && 25,
    },
    subHeader: {
      width: '100%',
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      padding: theme.spacing(1, 2),
    },
    largeAvatar: {
      width: theme.spacing(10),
      height: theme.spacing(10),
    },
    driverDetails: {
      padding: theme.spacing(0, 2),
    },
    phoneButton: {
      color: theme.palette.primary.main,
      backgroundColor: fade(theme.palette.primary.main, 0.04),
      marginTop: theme.spacing(2),
      width: 'fit-content',
    },
    activeOnly: {
      fontSize: 14,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-end',
    },
    orderTitle: {
      fontSize: 14,
      color: theme.palette.primary.main,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-start',
    },
    orderTitleText: {
      fontSize: 14,
      paddingLeft: theme.spacing(1),
    },
    refreshButton: {
      margin: theme.spacing(1.5, 2),
      top: 0,
      right: 0,
      position: 'absolute',
    },
  })
);
