import React, { useEffect, useMemo } from 'react';
import { Box, Button, Typography } from '@material-ui/core';
import { Pagination } from '@material-ui/lab';
import PropTypes from 'prop-types';
import { DriverItem } from '../../../layout/DriverItem';
import {
  Accordion,
  AccordionHeader,
  AccordionSkeleton,
} from '../../../layout/Accordion';
import { usePaginatedDriversHistory } from '../OrderHistoryPage.hooks';

export function _DriverSortedList({
  type = 'default',
  dateRange,
  setMetadata,
}) {
  const [page, setPage] = React.useState(1);

  const { drivers, isFetching, isError, refetch } = usePaginatedDriversHistory({
    page,
    dateRange,
  });
  const results = drivers?.results;
  const totalPages = drivers?.totalPages;

  useEffect(() => {
    if (drivers)
      setMetadata({
        totalResults: drivers.totalResults,
        totalEarned: drivers.totalEarned,
      });
  }, [drivers, setMetadata]);

  const Content = useMemo(() => {
    if (isError) {
      return (
        <Box alignItems="center" justifyContent="center" display="flex" p={2}>
          <Typography>We had a problem loading the requested items</Typography>
          <Button
            data-test-id="nbnJE3qUg_6c_seeBvGnD"
            padding={2}
            onClick={refetch}
          >
            Retry
          </Button>
        </Box>
      );
    }
    if (isFetching) {
      return Array(10)
        .fill()
        .map((_, i) => <AccordionSkeleton key={i} />);
    }
    if (results) {
      return results?.map(({ id, firstName, lastName, deliveries, earned }) => (
        <Accordion
          data-testid="driver-list:accordion"
          key={id}
          expanded={false}
        >
          <AccordionHeader hideExpandIcon hideCursor>
            <DriverItem
              key={id}
              type={type}
              firstName={firstName}
              lastName={lastName}
              active={deliveries?.active}
              orders={deliveries?.done}
              earned={earned}
              hideAvatar
              hideAction
              hideRipple
              disableGutters
            />
          </AccordionHeader>
        </Accordion>
      ));
    }
    return null;
  }, [isFetching, isError, results, refetch, type]);

  return (
    <>
      <Box display="flex" justifyContent="center" paddingY={2}>
        <Pagination
          count={totalPages}
          page={page}
          onChange={(_, value) => {
            setPage(value);
          }}
        />
      </Box>
      {Content}
    </>
  );
}

_DriverSortedList.propTypes = {
  type: PropTypes.oneOf(['default', 'order-history']),
  setMetadata: PropTypes.func,
  dateRange: PropTypes.arrayOf(PropTypes.any),
};

export const DriverSortedList = React.memo(_DriverSortedList);
