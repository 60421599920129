import {
  endOfDay,
  endOfMonth,
  endOfWeek,
  startOfDay,
  startOfMonth,
  startOfWeek,
  subDays,
  subWeeks,
  subMonths,
} from 'date-fns';

const getDateRanges = (dateNow) => {
  const weekOptions = { weekStartsOn: 1 };

  return {
    today: [startOfDay(dateNow), endOfDay(dateNow)],
    yesterday: [startOfDay(subDays(dateNow, 1)), endOfDay(subDays(dateNow, 1))],
    'this-week': [
      startOfWeek(dateNow, weekOptions),
      endOfWeek(dateNow, weekOptions),
    ],
    'last-week': [
      startOfWeek(subWeeks(dateNow, 1), weekOptions),
      endOfWeek(subWeeks(dateNow, 1), weekOptions),
    ],
    'this-month': [startOfMonth(dateNow), endOfMonth(dateNow)],
    'last-month': [
      startOfMonth(subMonths(dateNow, 1)),
      endOfMonth(subMonths(dateNow, 1)),
    ],
  };
};

export default getDateRanges;
