const { makeStyles } = require('@material-ui/core');

export const useStyles = makeStyles((theme) => ({
  desktopColumn: {
    display: 'flex',
    flexDirection: 'column',
    flex: '0 0 408px',
    backgroundColor: theme.palette.grey[100],
    overflowY: 'scroll',
    overflowX: 'hidden',

    '&::-webkit-scrollbar': {
      width: '0.4em',
    },
    '&::-webkit-scrollbar-track': {
      boxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
      webkitBoxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: 'rgba(0,0,0,.1)',
    },
  },
  mapContainerDesktop: {
    height: '100vh',
    width: '100%',
    overflow: 'hidden',
    position: 'relative',
  },
  desktopHeader: {
    padding: theme.spacing(7, 1),
    position: 'relative',
  },
  desktopFooter: {
    padding: theme.spacing(7, 0),
    position: 'relative',
  },
  body: {
    position: 'relative',
    height: '100%',
  },
  paddingBottom: {
    paddingBottom: '100px',
  },
}));
