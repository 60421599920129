import { createStyles, makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) =>
  createStyles({
    listItem: {
      width: '100%',
    },
    primaryText: {
      fontWeight: 500,
      color: '#000000DE',
      fontSize: 16,
    },
    secondaryContainer: {
      fontSize: 12,
      display: 'flex',
      alignItems: 'center',
    },
    activeText: {
      fontSize: 12,
      color: ({ status }) =>
        theme.palette.driverStatus?.[status] || theme.palette.primary.main,
    },
    ordersText: {
      fontSize: 12,
    },
    earnedText: {
      fontSize: 12,
    },
    boldText: {
      fontWeight: 'bold',
    },
    divider: {
      marginRight: theme.spacing(1),
      marginLeft: theme.spacing(1),
      borderRadius: 1,
      width: 2,
      height: 2,
      background: '#0000008A 0% 0% no-repeat padding-box',
    },
    driverAvatar: {
      color: '#212121',
      border: '2px solid #2176EA',
      borderColor: ({ status }) =>
        theme.palette.driverStatus?.[status] || theme.palette.primary.main,
      background: '#EBEBEB 0% 0% no-repeat padding-box',
    },
    rightIcon: {
      color: '#212121',
    },
  })
);
