import React, {
  useCallback,
  useRef,
  useState,
  createContext,
  useContext,
} from 'react';
import PropTypes from 'prop-types';
import { useThrottle } from '@react-hook/throttle';
import { _centerMapOnBounds } from './MapContext.functions';

export const initialState = {
  mapRef: { current: null },
  groupRef: { current: null },
  initialPosition: {
    center: {
      lat: 0,
      lng: 0,
    },
    zoom: 3,
  },
  markers: [],
  centerMapOnMarkerBounds: null,
  updateMarkers: null,
  setInitialPosition: null,
};

export const MapContext = createContext({
  mapRef: initialState.mapRef,
  groupRef: initialState.groupRef,
  markers: initialState.markers,
  updateMarkers: initialState.updateMarkers,
  centerMapOnMarkerBounds: initialState.centerMapOnMarkerBounds,
  initialPosition: initialState.initialPosition,
  setInitialPosition: initialState.setInitialPosition,
});

export const useMapContext = () => useContext(MapContext);

export function MapContextProvider({ children }) {
  const [markers, setMarkers] = useState(initialState.markers);
  const [initialPosition, setInitialPosition] = useThrottle(
    initialState.initialPosition,
    2
  );
  const mapRef = useRef(null);
  const groupRef = useRef(null);

  const centerMapOnMarkerBounds = ({ markers: newMarkers, shouldZoom }) =>
    _centerMapOnBounds({
      markers: newMarkers || markers,
      shouldZoom,
      mapRef,
      groupRef,
    });

  const updateMarkers = useCallback(
    async (newMarkers, { centerAfterUpdate, shouldZoom }) => {
      await setMarkers(newMarkers);
      if (centerAfterUpdate) {
        centerMapOnMarkerBounds({ markers: newMarkers, shouldZoom });
      }
    },
    // eslint-disable-next-line
    [setMarkers]
  );

  return (
    <MapContext.Provider
      value={{
        mapRef,
        groupRef,
        markers,
        updateMarkers,
        centerMapOnMarkerBounds,
        initialPosition,
        setInitialPosition,
      }}
    >
      {children}
    </MapContext.Provider>
  );
}

MapContextProvider.propTypes = {
  children: PropTypes.node,
};
