import React from 'react';
import PropTypes from 'prop-types';

import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles';

const theme = createMuiTheme({
  palette: {
    primary: {
      light: '#2176EA',
      main: '#0957EB',
    },
    secondary: {
      main: '#FF28FF',
      dark: '#B418AE',
    },
    driverStatus: {
      busy: '#2176EA',
      free: '#FF28FF',
      soon: '#B418AE',
    },
  },
});

export function Theme({ children }) {
  return <ThemeProvider theme={theme}>{children}</ThemeProvider>;
}

Theme.propTypes = {
  children: PropTypes.element.isRequired,
};
