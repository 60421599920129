import { useCallback, useMemo, useState } from 'react';

export const filterByProperty = (data, filters, property) => {
  if (
    !filters?.[property] ||
    filters?.[property]?.length === 0 ||
    filters?.[property] === 'all' ||
    filters?.[property]?.includes('all')
  ) {
    return data;
  }
  if (Array.isArray(filters?.[property])) {
    return data.filter((item) =>
      filters?.[property].includes(item?.[property])
    );
  }
  return data.filter((item) => item?.[property] === filters?.[property]);
};

export const useFiltersByKey = (rawData, defaultFilters = {}) => {
  const [filters, setFilters] = useState(defaultFilters);
  const onChange = useCallback(
    (e) => {
      const value = e?.target?.value;
      const name = e?.target?.name;
      setFilters({ [name]: value });
    },
    [setFilters]
  );

  const data = useMemo(
    () =>
      Object.keys(filters).reduce(
        (acc, cur) => filterByProperty(acc, filters, cur),
        rawData || []
      ),
    [rawData, filters]
  );

  return {
    data,
    filters,
    onChange,
  };
};
