import React from 'react';
import { useSelector } from 'react-redux';

import clsx from 'clsx';

import { makeStyles } from '@material-ui/core/styles';

import { BrowserRouter as Switch, Route } from 'react-router-dom';

import { Header } from '../../layout/Header/Header';
import { Footer } from '../../layout/Footer/Footer';

import { DashboardSection } from '../section/DashboardSection';
import { PeopleSection } from '../section/PeopleSection';
import { OperationsSection } from '../section/OperationsSection';
import { ProfileSection } from '../section/ProfileSection';
import { NotificationsSection } from '../section/NotificationsSection';

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: 0,
  },
  contentShift: {
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: drawerWidth,
  },
}));

export function MainPage() {
  const classes = useStyles();

  const leftMenuDrawerOpen = useSelector(
    (state) => state.drawer.leftMenuDrawerOpen
  );

  return (
    <>
      <Header />
      <main
        className={clsx(classes.content, {
          [classes.contentShift]: leftMenuDrawerOpen,
        })}
      >
        <div className={classes.drawerHeader} />
        <Switch>
          <Route exact path="/">
            <DashboardSection />
          </Route>
          <Route path="/dashboard">
            <DashboardSection />
          </Route>
          <Route path="/operations">
            <OperationsSection />
          </Route>
          <Route path="/people">
            <PeopleSection />
          </Route>
          <Route path="/profile">
            <ProfileSection />
          </Route>
          <Route path="/notifications">
            <NotificationsSection />
          </Route>
        </Switch>
      </main>
      <Footer />
    </>
  );
}
