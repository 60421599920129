import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
  chipFilter: {
    boxShadow: theme.shadows[2],
    backgroundColor: theme.palette.common.white,
    padding: theme.spacing(0.1),
    fontWeight: 500,
    '&:hover': {
      // you want this to be the same as the backgroundColor above
      backgroundColor: theme.palette.common.white,
    },
    '&:focus': {
      backgroundColor: `${theme.palette.common.white} !important`,
    },

    marginRight: theme.spacing(1),
  },

  chiplabel: {
    fontSize: 10,
  },

  chipIcon: {
    fontSize: 12,
  },
}));
