import React from 'react';

import { Router, Switch } from 'react-router-dom';

import CssBaseline from '@material-ui/core/CssBaseline';
import Box from '@material-ui/core/Box';

import { CircularProgress, Typography } from '@material-ui/core';
import Route from '../../navigation/Route';
import history from '../../../utils/history';
import { useStyles } from './Root.styles';
import { LoginPage } from '../../page/LoginPage';
import { HomePage } from '../../page/HomePage';
import { DriverPage } from '../../page/DriverPage';
import { OrderHistoryPage } from '../../page/OrderHistoryPage';
import { MainPage } from '../../page/MainPage';
import { NotFoundPage } from '../../page/NotFoundPage';
import useAppLoading from '../../../hooks/useAppLoading';

import Notifier from '../Notifier/Notifier';
import { MapContextProvider } from '../../../context/MapContext';

const authRoutes = [
  '/dashboard',
  '/operations',
  '/people',
  '/profile',
  '/notifications',
];

export function Root() {
  const classes = useStyles();

  const { isAppLoading } = useAppLoading();

  if (isAppLoading) {
    return (
      <Box
        data-testid="apploading"
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
        display="flex"
        height="100%"
      >
        <Box marginBottom={2}>
          <CircularProgress />
        </Box>
        <Typography>Loading...</Typography>
      </Box>
    );
  }

  return (
    <MapContextProvider>
      <Box className={classes.rootbox}>
        <CssBaseline />
        <Router history={history}>
          <Switch>
            <Route exact path="/" isPrivate component={HomePage} />
            <Route isPrivate path="/drivers/:id" component={DriverPage} />
            <Route isPrivate path="/history" component={OrderHistoryPage} />

            {authRoutes.map((routePath, i) => (
              <Route key={i} path={routePath} isPrivate component={MainPage} />
            ))}
            <Route path="/login" component={LoginPage} />
            <Route path="*" component={NotFoundPage} />
          </Switch>
        </Router>
        <Notifier />
      </Box>
    </MapContextProvider>
  );
}
