import React, { useCallback } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers';
import PropTypes from 'prop-types';

export default function Form({
  defaultValues,
  schema,
  children,
  onSubmit,
  ...rest
}) {
  const { handleSubmit, errors, register } = useForm({
    defaultValues,
    resolver: schema ? yupResolver(schema) : undefined,
  });

  const getErrors = useCallback(
    (path) => ({
      error: !!errors[path],
      helperText: errors[path] && errors[path].message,
    }),
    [errors]
  );

  return (
    <form noValidate onSubmit={handleSubmit(onSubmit)} {...rest}>
      {React.Children.map(children, (child) =>
        child.props.name
          ? React.createElement(child.type, {
              ...{
                ...child.props,
                register,
                key: child.props.name,
                ...getErrors(child.props.name),
              },
            })
          : child
      )}
    </form>
  );
}

Form.propTypes = {
  defaultValues: PropTypes.any,
  schema: PropTypes.any,
  children: PropTypes.node,
  onSubmit: PropTypes.func,
};
