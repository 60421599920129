import React from 'react';
import { Route as ReactDOMRoute, Redirect } from 'react-router-dom';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { LOGIN_PATH, ROOT_PATH } from '../../../constants/routes';
import history from '../../../utils/history';

function Route({ isPrivate, component: Component, ...rest }) {
  const user = useSelector((state) => state.user.user);

  const currentLocation =
    history.location.pathname && history.location.pathname !== LOGIN_PATH
      ? history.location.pathname
      : ROOT_PATH;

  return (
    <ReactDOMRoute
      {...rest}
      render={() => {
        if (!user && isPrivate) {
          return (
            <Redirect
              to={{
                pathname: LOGIN_PATH,
                state: { from: currentLocation },
              }}
            />
          );
        }
        return <Component />;
      }}
    />
  );
}

Route.propTypes = {
  isPrivate: PropTypes.bool,
  component: PropTypes.func.isRequired,
};

Route.defaultProps = {
  isPrivate: false,
};

export default Route;
