import { formatRelative } from 'date-fns';
import { enUS } from 'date-fns/esm/locale';

export const formatRelativeLocale = {
  lastWeek: "'Last' eeee 'at' p",
  yesterday: "'Yesterday at' p",
  today: "'Today at' p",
  tomorrow: "'Tomorrow at' p",
  nextWeek: "eeee 'at' p",
  other: "P 'at' p", // Difference: Add time to the date
};

const locale = {
  ...enUS,
  formatRelative: (token) => formatRelativeLocale[token],
};

const formatRelativeDeliveryTime = (date, baseDate, options) =>
  formatRelative(date, baseDate, { locale, ...options });

export default formatRelativeDeliveryTime;
