import React from 'react';
import { Box, Paper } from '@material-ui/core';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { formatMoney } from '../../../utils/StringUtils';
import { useStyles } from './OrderHistoryFooter.styles';

function OrderHistoryFooter({
  driversTitle = 'Drivers',
  drivers = 0,
  earnedTitle = 'Earned',
  earned = 0,
}) {
  const classes = useStyles();

  return (
    <Paper className={classes.container} variant="outlined">
      <Box className={classes.content}>
        <Box className={classes.leftContainer}>
          <Box
            className={clsx(classes.metaContainer, classes.spacing)}
            data-testid="order-history-footer:drivers"
          >
            <Box className={classes.metaTitle}>{driversTitle}</Box>
            <Box className={classes.metaValue}>{drivers}</Box>
          </Box>
        </Box>
        <Box className={classes.rightContainer}>
          <Box
            className={classes.metaContainer}
            data-testid="order-history-footer:earned"
          >
            <Box className={classes.metaTitle}>{earnedTitle}</Box>
            <Box className={classes.metaValue}>{formatMoney(earned)}</Box>
          </Box>
        </Box>
      </Box>
    </Paper>
  );
}

OrderHistoryFooter.propTypes = {
  drivers: PropTypes.number,
  earned: PropTypes.number,
};

export default OrderHistoryFooter;
