import { usePaginatedQuery, useQuery } from 'react-query';
import {
  getDriversHistory,
  getOrderDetails,
  getOrderHistory,
} from '../../../services/DeliveryOperationsPortalService';

export const useDeliveryItem = ({ id }) => {
  const {
    data: delivery,
    isLoading,
    isError,
  } = useQuery(['delivery-item', id], (key, id) => getOrderDetails(id));

  return {
    delivery,
    isLoading,
    isError,
  };
};

export const usePaginatedHistory = ({ page, dateRange }) => {
  const {
    isLoading,
    isError,
    resolvedData: orders,
    isFetching,
    isFetchingMore,
    refetch,
    ...rest
  } = usePaginatedQuery(
    ['order-history', page, dateRange],
    (key, page, dateRange) =>
      getOrderHistory({
        page,
        dateRange,
      }),
    { keepPreviousData: false }
  );

  return {
    orders,
    isLoading,
    isFetching,
    isFetchingMore,
    isError,
    refetch,
    ...rest,
  };
};

export const usePaginatedDriversHistory = ({ page, dateRange }) => {
  const {
    isLoading,
    isError,
    resolvedData: drivers,
    isFetching,
    isFetchingMore,
    refetch,
    ...rest
  } = usePaginatedQuery(
    ['drivers-history', page, dateRange],
    (key, page, dateRange) =>
      getDriversHistory({
        page,
        dateRange,
      }),
    { keepPreviousData: false }
  );

  return {
    drivers,
    isLoading,
    isFetching,
    isFetchingMore,
    isError,
    refetch,
    ...rest,
  };
};
