import React from 'react';
import clsx from 'clsx';
import { Chip } from '@material-ui/core';
import PropTypes from 'prop-types';
import { useStyles } from './FilterChip.styles';

export function FilterChip({ selected, icon, ...rest }) {
  const classes = useStyles();

  const variant = clsx({
    outlined: selected,
    default: !selected,
  });
  const color = clsx({
    primary: selected,
    default: !selected,
  });

  const Icon = icon;

  return (
    <Chip
      classes={{
        outlinedPrimary: classes.chipOutlinedPrimary,
        label: classes.label,
      }}
      className={classes.chipFilter}
      // size="small"
      clickable
      variant={variant}
      color={color}
      icon={<Icon className={classes.chipIcon} />}
      {...rest}
    />
  );
}

FilterChip.propTypes = {
  selected: PropTypes.bool,
  icon: PropTypes.any,
};

FilterChip.defaultProps = {
  selected: false,
  icon: null,
};
