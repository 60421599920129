import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { Toolbar } from '@material-ui/core';
import { FilterChip } from '../../units/FilterChip';
import useSelectFields from '../../../hooks/useSelectFields';
import { useStyles } from './ChipFilterList.styles';

export function ChipFilterList({
  onChange,
  name,
  isMultiple,
  value,
  selectAllOption,
  options,
}) {
  const classes = useStyles();
  const { isSelected, handleSelection, handleSelectAll, areAllSelected } =
    useSelectFields({
      onChange,
      name,
      isMultiple,
      value,
      enableSelectAll: true,
      enableUnselect: false,
    });

  const allOptionsIds = useMemo(() => options.map(({ id }) => id), [options]);

  return (
    <Toolbar disableGutters className={classes.filtersContainer}>
      {selectAllOption && (
        <FilterChip
          data-test-id="2ByHkdBdu5vpF0MmSGavT"
          key={selectAllOption.id}
          onClick={(e) => handleSelectAll(e, allOptionsIds)}
          label={selectAllOption.title}
          selected={areAllSelected(allOptionsIds)}
          icon={selectAllOption.icon}
        />
      )}
      {options?.map((option) => (
        <FilterChip
          data-test-id="vOQOo88lWkzk5_s1mTRqj"
          key={option.id}
          onClick={(e) => handleSelection(e, option.id)}
          label={option.title}
          selected={isSelected(option.id)}
          icon={option.icon}
        />
      ))}
    </Toolbar>
  );
}

ChipFilterList.propTypes = {
  onChange: PropTypes.func.isRequired,
  value: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.number),
    PropTypes.arrayOf(PropTypes.string),
    PropTypes.string,
    PropTypes.number,
  ]),
  isMultiple: PropTypes.bool,
  name: PropTypes.string.isRequired,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
      title: PropTypes.string.isRequired,
      icon: PropTypes.any.isRequired,
    }).isRequired
  ),
  selectAllOption: PropTypes.shape({
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    title: PropTypes.string.isRequired,
    icon: PropTypes.any.isRequired,
  }),
};

ChipFilterList.defaultProps = {
  value: [],
  isMultiple: false,
  selectAllOption: null,
};

export default ChipFilterList;
