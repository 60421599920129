import React, { useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import { Paper, Typography, List, IconButton, Box } from '@material-ui/core';
import { List as ListIcon, Refresh as RefreshIcon } from '@material-ui/icons';
import { useHistory } from 'react-router-dom';
import { useListStyles } from './DriversList.styles';
import useIsDesktop from '../../../hooks/useIsDesktop/useIsDesktop';
import { DriverItemSkeleton, DriverItem } from '../DriverItem';
import { withHover } from '../../../context/HoverContext';

const DriverItemWithHover = withHover(DriverItem);
export default function DriversList({
  type,
  onHeaderPress,
  onRefreshPress,
  list,
  isLoading,
  isHoverable,
  emptyText,
}) {
  const isDesktop = useIsDesktop();
  const classes = useListStyles({ isDesktop });
  const history = useHistory();

  const navigateToDriver = useCallback(
    (id) => {
      history.push(`/drivers/${id}`);
    },
    [history]
  );

  const ListDriverItem = useMemo(
    () => (isHoverable ? DriverItemWithHover : DriverItem),
    [isHoverable]
  );
  const handleRefreshPress = (e) => {
    e.stopPropagation();
    onRefreshPress();
  };

  const listLength = list?.length || 0;

  function Header() {
    return (
      <div className={classes.headerContainer}>
        <div
          data-test-id="5hVun_qB9RW1b3Sl7Ov2N"
          className={classes.header}
          aria-label="driver list header"
          onClick={onHeaderPress}
        >
          <Box
            flex={1}
            display="flex"
            alignItems="center"
            justifyContent="flex-start"
          >
            <ListIcon className={classes.headerIcon} />
            <Typography className={classes.headerText}>
              {listLength} Drivers
            </Typography>
          </Box>
          <Box
            flex={1}
            display="flex"
            alignItems="center"
            justifyContent="flex-end"
          >
            <IconButton
              data-test-id="Wnrlez51yzNBCz1mEV1L5"
              size="small"
              onClick={handleRefreshPress}
              disabled={isLoading}
              aria-label="refresh drivers"
            >
              <RefreshIcon />
            </IconButton>
          </Box>
        </div>
      </div>
    );
  }

  return (
    <Paper className={classes.container} elevation={0}>
      <Header />
      <List className={classes.root} aria-label="drivers list">
        {isLoading
          ? Array(10)
              .fill()
              .map((_, i) => <DriverItemSkeleton key={i} />)
          : list?.map(
              ({
                id,
                firstName,
                lastName,
                status,
                deliveries: { active, done },
                earned,
              }) => (
                <ListDriverItem
                  data-test-id="8XHACbNBVZwyAQR2GumB4"
                  type={type}
                  hoverId={id}
                  firstName={firstName}
                  status={status}
                  lastName={lastName}
                  active={active}
                  orders={done}
                  earned={earned}
                  key={id}
                  onClick={() => navigateToDriver(id)}
                />
              )
            )}
        {!isLoading && list?.length === 0 && emptyText && (
          <Box padding={2} textAlign="center">
            <Typography variant="body1">{emptyText}</Typography>
          </Box>
        )}
      </List>
    </Paper>
  );
}

DriversList.propTypes = {
  onHeaderPress: PropTypes.func,
  onRefreshPress: PropTypes.func,
  list: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      firstName: PropTypes.string,
      lastName: PropTypes.string,
      status: PropTypes.oneOf(['busy', 'free', 'soon']),
      deliveries: PropTypes.shape({
        active: PropTypes.number,
        done: PropTypes.number,
      }),
      location: PropTypes.shape({
        latitude: PropTypes.number,
        longitude: PropTypes.number,
      }),
      earned: PropTypes.number,
    })
  ),
  isLoading: PropTypes.bool,
  isHoverable: PropTypes.bool,
  emptyText: PropTypes.string,
};

DriversList.defaultProps = {
  onHeaderPress: () => {},
  onRefreshPress: () => {},
  list: [],
  isLoading: false,
  isHoverable: false,
  emptyText: 'No drivers',
};
