import { differenceInSeconds } from 'date-fns';
import BaseAuthentication from './base';
import { EXPIRED_TOKEN, FORCED_LOGOUT, USER_LOGOUT } from '../constants';

export default class OAuthAuthentication extends BaseAuthentication {
  storage = null;

  constructor({
    storage,
    loginRequest,
    getProfileRequest,
    refreshTokenRequest,
    deauthorizeRequest,
    navigateToLogin,
    roleType,
    rolesAllowed,
  }) {
    super();
    this.storage = storage;
    this.navigateToLogin = navigateToLogin;
    this.loginRequest = loginRequest;
    this.getProfileRequest = getProfileRequest;
    this.refreshTokenRequest = refreshTokenRequest;
    this.deauthorizeRequest = deauthorizeRequest;
    this.roleType = roleType;
    this.rolesAllowed = rolesAllowed;
  }

  login = async (email, password) => {
    const authInfo = await this.loginRequest({ email, password });
    await this.storage.set('user', authInfo);
  };

  getToken = async () => {
    const authInfo = await this.storage.get('user');

    if (!authInfo) return null;
    const accessToken = authInfo?.access?.token;
    const expiresIn = authInfo?.access?.expires * 1000;

    if (!this._isTokenExpired(accessToken, expiresIn)) {
      return { token: accessToken, isExpired: false };
    }
    try {
      const refreshedToken = await this.refreshToken();
      return {
        token: refreshedToken?.access?.token,
        isExpired: this._isTokenExpired(
          refreshedToken?.access?.token,
          refreshedToken?.access?.expires * 1000
        ),
      };
    } catch (err) {
      await this.logout(EXPIRED_TOKEN);
      return { token: accessToken, isExpired: true };
    }
  };

  _hasRolePermissions = async (roles) => {
    const { roleType } = this;
    const rolesAllowed = this.rolesAllowed?.split(',');
    if (!roleType || !rolesAllowed) return true;
    return roles[roleType] && rolesAllowed.includes(roles[roleType]);
  };

  getProfile = async () => {
    try {
      const profile = await this.getProfileRequest();
      const hasPermissions = await this._hasRolePermissions(profile.roles);
      if (!hasPermissions) {
        throw new Error('Insufficient permissions');
      }
      return profile;
    } catch (err) {
      if (err?.message === 'Insufficient permissions') throw err;
      throw Error('Unable to get profile');
    }
  };

  _isTokenExpired = (token, expiresIn) =>
    !token || differenceInSeconds(new Date(expiresIn), new Date()) < 0;

  refreshToken = async () => {
    const authInfo = await this.storage.get('user');

    if (authInfo?.refresh) {
      try {
        const refreshToken = await this.refreshTokenRequest(
          authInfo?.refresh?.token
        );
        if (!refreshToken) throw new Error();
        const newToken = {
          ...authInfo,
          access: refreshToken,
        };
        await this.storage.set('user', newToken);
        return newToken;
      } catch (err) {
        throw Error('Unable to refresh token');
      }
    } else throw Error('Unable to refresh token');
  };

  logout = async (reason) => {
    try {
      const authInfo = await this.storage.get('user');
      if (authInfo?.refresh) {
        await this.deauthorizeRequest(authInfo?.refresh?.token);
      }
    } catch {
      console.error('Error deauthorizing account');
    } finally {
      await this.storage.remove('user');
      this.navigateToLogin();
      return this.logoutCallback(reason);
    }
  };

  logoutCallback = async (reason) => {
    switch (reason) {
      case EXPIRED_TOKEN:
        return {
          message: 'Session Expired',
          options: {
            variant: 'warning',
          },
        };
      case FORCED_LOGOUT:
        return {
          message: 'Session invalidated',
          options: {
            variant: 'error',
          },
        };
      case USER_LOGOUT:
        return {
          message: 'Success logout',
          options: {
            variant: 'success',
          },
        };

      default:
        return null;
    }
  };
}
