import React, { useEffect, useState, createContext, useContext } from 'react';
import PropTypes from 'prop-types';
import useHover from '../hooks/useHover';

export const HoverContext = createContext({
  hoverId: null,
  setHoverId: null,
});

export const toggleHover = (setHoverId, isHovering, hoverId) => {
  const result = isHovering ? hoverId : null;
  setHoverId(result);
};

export function withHover(Component) {
  Component.displayName = 'HoveredComponent';
  function SubComponent(props) {
    const { setHoverId } = useHoverContext();
    const [isHovering, onMouseEnter, onMouseLeave] = useHover({
      mouseEnterDelayMS: 500,
      mouseLeaveDelayMS: 100,
    });
    const hoverId = props?.hoverId;
    useEffect(() => {
      toggleHover(setHoverId, isHovering, hoverId);
    }, [isHovering, setHoverId, hoverId]);
    return (
      <div onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave}>
        <Component {...props} />
      </div>
    );
  }
  SubComponent.propTypes = {
    hoverId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  };

  return React.memo(SubComponent);
}
withHover.propTypes = {
  Component: PropTypes.element,
};

export const useHoverContext = () => useContext(HoverContext);

export function HoverContextProvider({ children }) {
  const [hoverId, setHoverId] = useState(null);

  return (
    <HoverContext.Provider value={{ hoverId, setHoverId }}>
      {children}
    </HoverContext.Provider>
  );
}

HoverContextProvider.propTypes = {
  children: PropTypes.node,
};
