import fetchIntercept from 'fetch-intercept';
import { matchPath } from 'react-router-dom';
import Environment from '@eatzy/common-reactjs/Environment';
import { EXPIRED_TOKEN } from '../../constants/auth';
import { UNAUTHORIZED } from '../../constants/http';
import { logoutRequest } from '../../redux/ducks/UserDucks';
import Authentication from '../../utils/authentication';

export const authorizationTokenRoutes = [
  { method: 'post', endpoint: '/account/authorize' },
  { method: 'post', endpoint: '/account/refresh' },
  { method: 'post', endpoint: '/account/deauthorize' },
];

const matchesRoute = ({ url, method }, endpoints) => {
  const matchesMethod = endpoints.some(
    (route) => route.method.toLowerCase() === method.toLowerCase()
  );

  const matchesURL = endpoints.some((route) =>
    matchPath(url.pathname, {
      path: route.endpoint,
      exact: true,
      strict: false,
    })
  );

  return !!(matchesMethod && matchesURL);
};

export const insertUseRandomFakeData = async (url, config) => {
  const useFakeData = Environment.getEnv('API_FAKE_MODE');

  if (useFakeData === 'true' && config?.headers) {
    config.headers = {
      ...config.headers,
      'X-API-FakeData': 'random',
    };
  }

  return [url, config];
};

export const insertBasicHeaders = async (url, config) => {
  config.headers = {
    ...config.headers,
    'Content-Type': 'application/json',
    'X-App-Version': process.env.REACT_APP_VERSION,
    'X-App-Source': 'delivery-operations-web',
    'X-App-OS':
      window?.navigator?.platform ||
      window?.navigator?.userAgentData?.platform ||
      'unknown',
  };

  return [url, config];
};

export const insertAuthorizationTokenInRequest = async (url, config) => {
  if (config.hasRefreshToken) {
    config.headers = {
      ...config.headers,
      authorization: `Basic ${config.refreshToken}`,
    };
  }

  return [url, config];
};

export const insertAccessTokenInRequest = async (url, config, store) => {
  const authToken = await Authentication.getToken();

  if (!authToken) {
    return [url, config];
  }
  if (authToken?.isExpired) {
    if (store) store.dispatch(logoutRequest(EXPIRED_TOKEN));
    return [url, config];
  }

  config.headers = {
    ...config.headers,
    authorization: `Bearer ${authToken?.token}`,
  };

  return [url, config];
};

export const interceptors = (store) => ({
  request(url, config) {
    insertBasicHeaders(url, config);
    insertUseRandomFakeData(url, config);

    // Modify the url or config here
    const refreshRelated = matchesRoute(
      { url, method: config.method },
      authorizationTokenRoutes
    );

    if (refreshRelated || config?.hasRefreshToken) {
      return insertAuthorizationTokenInRequest(url, config, store);
    }
    return insertAccessTokenInRequest(url, config, store);
  },

  requestError(error) {
    // Called when an error occured during another 'request' interceptor call
    return Promise.reject(error);
  },

  async response(response) {
    // Modify the reponse object
    return new Promise(async (resolve, reject) => {
      if (response.status === UNAUTHORIZED) {
        reject({ message: 'Unauthorized', status: 401 });
      }
      if (response.status === 403) {
        reject({ message: 'Forbidden', status: 403 });
      }
      const data = await response.json();
      if (response.ok) {
        resolve(data);
      } else {
        reject(data);
      }
    });
  },

  responseError(error) {
    // Handle an fetch error
    return Promise.reject(error);
  },
});

export default (store) => fetchIntercept.register(interceptors(store));
