import React from 'react';

import Container from '@material-ui/core/Container';
import { makeStyles } from '@material-ui/core/styles';
import { Footer } from '../../layout/Footer/Footer';

const useStyles = makeStyles(() => ({
  rootcontainer: {
    flexGrow: 1,
    height: '100%',
  },
}));

export function NotFoundPage() {
  const classes = useStyles();

  return (
    <>
      <Container
        component="main"
        maxWidth="xl"
        className={classes.rootcontainer}
      >
        Page Not Found
      </Container>
      <Footer />
    </>
  );
}
