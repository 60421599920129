// Dependencies
import React from 'react';
import 'leaflet/dist/leaflet.css';
import { Provider } from 'react-redux';
import { SnackbarProvider } from 'notistack';
import { QueryCache, ReactQueryCacheProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query-devtools';
import Environment from '@eatzy/common-reactjs/Environment';
import ConfigurationChecker from '@eatzy/common-reactjs/ConfigurationChecker';
import { defaultStore } from '../../../redux/stores/GlobalStore';
import { Root } from '../Root';
import { Theme as CustomTheme } from '../Theme';
import { ErrorBoundary } from '../../../utils/errorHandler';

import registerInterceptors from '../../../services/interceptors/AccountServiceInterceptors';

const configurationChecker = new ConfigurationChecker(Environment.getEnv, [
  'EATZY_ENVIRONMENT',
  'BUGSNAG_API_KEY',
  'API_URL',
  'VERSION',
]);

registerInterceptors(defaultStore);

Environment.parseInitialEnv();
configurationChecker.check();

const queryCache = new QueryCache({
  defaultConfig: {
    queries: {
      refetchOnWindowFocus: false,
      refetchInterval: false,
      retry: false,
    },
  },
});

function App() {
  return (
    <ErrorBoundary>
      <CustomTheme>
        <Provider store={defaultStore}>
          <SnackbarProvider
            maxSnack={3}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'center',
            }}
            preventDuplicate
          >
            <ReactQueryCacheProvider queryCache={queryCache}>
              <Root />
              <ReactQueryDevtools />
            </ReactQueryCacheProvider>
          </SnackbarProvider>
        </Provider>
      </CustomTheme>
    </ErrorBoundary>
  );
}

export default App;
