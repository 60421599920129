import React, { useState } from 'react';
import clsx from 'clsx';
import Container from '@material-ui/core/Container';
import { Box } from '@material-ui/core';
import SwipeableBottomSheet from 'react-swipeable-bottom-sheet';
import PropTypes from 'prop-types';
import { SearchMenuBar } from '../../navigation/SearchMenuBar';
import ChipFilterList from '../../layout/ChipFilterList';
import DriversList from '../../layout/DriversList';
import { useStyles } from './HomePage.styles';
import useViewportHeight from '../../../hooks/useViewportHeight/useViewportHeight';
import { LeftMenuDrawer } from '../../navigation/LeftMenuDrawer/LeftMenuDrawer';
import { DesktopLayout } from '../../layout/DesktopLayout/DesktopLayout';
import ActiveDriversMap from '../../layout/ActiveDriversMap/ActiveDriversMap';
import {
  useActiveDrivers,
  useActiveDriversRequest,
} from '../../../hooks/useActiveDrivers/useActiveDrivers';
import useIsDesktop from '../../../hooks/useIsDesktop/useIsDesktop';
import { HoverContextProvider } from '../../../context/HoverContext';

export function _RenderDesktop({
  onFilterChange,
  filters,
  filterOptions,
  driverListLoading,
  filteredDriverList,
  refetch,

  onSearch,
}) {
  const classes = useStyles();
  return (
    <main className={clsx(classes.content)} aria-label="desktop content">
      <LeftMenuDrawer />

      <DesktopLayout
        HeaderComponent={
          <Box className={classes.desktopSearchBar}>
            <SearchMenuBar onSearch={onSearch} />
          </Box>
        }
        MapComponent={
          <ActiveDriversMap
            filterOptions={filterOptions}
            onFilterChange={onFilterChange}
            filters={filters}
            groupMarkers={false}
          />
        }
      >
        <DriversList
          isHoverable
          emptyText="No active drivers"
          list={filteredDriverList}
          isLoading={driverListLoading}
          onRefreshPress={refetch}
        />
      </DesktopLayout>
    </main>
  );
}

export const RenderDesktop = React.memo(_RenderDesktop);

_RenderDesktop.propTypes = {
  onFilterChange: PropTypes.func,
  filters: PropTypes.objectOf(PropTypes.string),
  filterOptions: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      title: PropTypes.string,
      icon: PropTypes.any,
    })
  ),
  driverListLoading: PropTypes.bool,
  filteredDriverList: PropTypes.any,
  refetch: PropTypes.func,
  onSearchTextChange: PropTypes.func,
};

function RenderMobile({
  onFilterChange,
  filters,
  filterOptions,
  driverListLoading,
  filteredDriverList,
  refetch,
  onSearch,
}) {
  const [bottomSheetOpen, setBottomSheetOpen] = useState(false);
  const vh = useViewportHeight();
  const classes = useStyles();
  return (
    <main className={clsx(classes.content)} aria-label="mobile content">
      <Container component="main" maxWidth="xl">
        <LeftMenuDrawer />
        <Box className={classes.header}>
          <SearchMenuBar onSearch={onSearch} />
          <ChipFilterList
            name="status"
            value={filters.status}
            onChange={onFilterChange}
            options={filterOptions}
          />
        </Box>
        <Box>
          <ActiveDriversMap
            filterOptions={filterOptions}
            onFilterChange={onFilterChange}
            filters={filters}
            groupMarkers={false}
          />
        </Box>
      </Container>
      <SwipeableBottomSheet
        open={bottomSheetOpen}
        onChange={setBottomSheetOpen}
        topShadow={false}
        overlay={false}
        overflowHeight={20 * vh}
        marginTop={20 * vh}
        style={{ background: 'white', zIndex: 3 }}
      >
        <DriversList
          emptyText="No active drivers"
          isLoading={driverListLoading}
          list={filteredDriverList}
          onHeaderPress={() => setBottomSheetOpen((old) => !old)}
          onRefreshPress={refetch}
        />
      </SwipeableBottomSheet>
    </main>
  );
}

RenderMobile.propTypes = {
  onFilterChange: PropTypes.func,
  filters: PropTypes.objectOf(PropTypes.string),
  filterOptions: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      title: PropTypes.string,
      icon: PropTypes.any,
    })
  ),
  driverListLoading: PropTypes.bool,
  filteredDriverList: PropTypes.any,
  refetch: PropTypes.func,
  onSearchTextChange: PropTypes.func,
};

export const HomePageContent = React.memo(() => {
  const isDesktop = useIsDesktop();

  const { driverList, driverListLoading, refetch } = useActiveDriversRequest();

  const {
    filteredDriverList,
    filterOptions,
    onFilterChange,
    onSearch,
    filters,
    refetch: refetchAndCenter,
  } = useActiveDrivers({
    driverList,
    driverListLoading,
    refetch,
  });

  return isDesktop ? (
    <RenderDesktop
      filteredDriverList={filteredDriverList}
      driverListLoading={driverListLoading}
      refetch={refetchAndCenter}
      filterOptions={filterOptions}
      onFilterChange={onFilterChange}
      filters={filters}
      onSearch={onSearch}
    />
  ) : (
    <RenderMobile
      filteredDriverList={filteredDriverList}
      driverListLoading={driverListLoading}
      refetch={refetchAndCenter}
      filterOptions={filterOptions}
      onFilterChange={onFilterChange}
      filters={filters}
      onSearch={onSearch}
    />
  );
});

export function HomePage() {
  return (
    <HoverContextProvider>
      <HomePageContent />
    </HoverContextProvider>
  );
}
