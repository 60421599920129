import { useThrottle } from '@react-hook/throttle';
import { useEffect, useCallback } from 'react';

const useViewportHeight = () => {
  const [vh, setVh] = useThrottle(window.innerHeight * 0.01, 2);

  const updateHeight = useCallback(() => {
    setVh(window.innerHeight * 0.01);
  }, [setVh]);

  useEffect(() => {
    window.addEventListener('resize', updateHeight);

    return () => window.removeEventListener('resize', updateHeight);
  }, [setVh, updateHeight]);

  return vh;
};

export default useViewportHeight;
