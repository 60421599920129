import React, { useMemo, useEffect } from 'react';
import {
  Box,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
} from '@material-ui/core';
import SortIcon from '@material-ui/icons/Sort';
import CalendarIcon from '@material-ui/icons/CalendarToday';
import PropTypes from 'prop-types';
import { getDateRanges } from '../../../../utils/DateUtils';
import { useStyles } from './SortFilter.styles';

export function SelectDateRange({ setDateRange }) {
  const classes = useStyles();
  const defaultDateRanges = useMemo(() => {
    const now = Date.now();
    return getDateRanges(now);
  }, []);

  useEffect(() => {
    setDateRange(defaultDateRanges?.today);
  }, [setDateRange, defaultDateRanges]);

  const handleDateChange = (e) => {
    const value = e?.target?.value;
    setDateRange(defaultDateRanges[value]);
  };

  return (
    <Select
      startAdornment={<CalendarIcon className={classes.inputIcon} />}
      labelId="order-date"
      defaultValue="today"
      onChange={handleDateChange}
    >
      <MenuItem value="today">Today</MenuItem>
      <MenuItem value="yesterday">Yesterday</MenuItem>
      <MenuItem value="this-week">This Week</MenuItem>
      <MenuItem value="last-week">Last Week</MenuItem>
      <MenuItem value="this-month">This Month</MenuItem>
      <MenuItem value="last-month">Last Month</MenuItem>
    </Select>
  );
}

SelectDateRange.propTypes = {
  setDateRange: PropTypes.func,
};

export function SelectGroupBy({ sortBy, setSortBy }) {
  const classes = useStyles();
  return (
    <Select
      startAdornment={<SortIcon className={classes.inputIcon} />}
      labelId="type"
      defaultValue="date"
      onChange={(e) => {
        setSortBy(e.target.value);
      }}
      value={sortBy}
    >
      <MenuItem value="date">Date</MenuItem>
      <MenuItem value="driver">Driver</MenuItem>
    </Select>
  );
}
SelectGroupBy.propTypes = {
  setSortBy: PropTypes.func,
  sortBy: PropTypes.string,
};

function SortFilter({ sortBy, setSortBy, setDateRange }) {
  const classes = useStyles();

  return (
    <Box className={classes.selectRow}>
      <Box className={classes.selectCol}>
        <FormControl className={classes.selectFormControl}>
          <InputLabel id="order-date">Orders Date</InputLabel>
          <SelectDateRange setDateRange={setDateRange} />
        </FormControl>
      </Box>
      <Box className={classes.selectCol}>
        <FormControl className={classes.selectFormControl}>
          <InputLabel id="type">Group By</InputLabel>
          <SelectGroupBy sortBy={sortBy} setSortBy={setSortBy} />
        </FormControl>
      </Box>
    </Box>
  );
}
export default SortFilter;

SortFilter.propTypes = {
  sortBy: PropTypes.string,
  setSortBy: PropTypes.func,
  setDateRange: PropTypes.func,
};
