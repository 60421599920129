import { createStyles, makeStyles } from '@material-ui/core';

export const useListStyles = makeStyles((theme) =>
  createStyles({
    container: ({ isDesktop }) =>
      isDesktop
        ? {
            position: 'relative',
            zIndex: 3,
            flex: 1,
            height: '100%',
          }
        : {
            position: 'relative',
            paddingTop: 48,
            zIndex: 3,
          },
    root: {
      width: '100%',
      backgroundColor: theme.palette.background.paper,
    },
    headerContainer: ({ isDesktop }) =>
      isDesktop
        ? {
            backgroundColor: theme.palette.grey[100],
          }
        : {},
    header: ({ isDesktop }) =>
      isDesktop
        ? {
            zIndex: 2,
            backgroundColor: theme.palette.common.white,
            justifyContent: 'flex-start',
            display: 'flex',
            alignItems: 'center',
            paddingRight: theme.spacing(2),
            paddingLeft: theme.spacing(2),
            paddingTop: theme.spacing(1.5),
            paddingBottom: theme.spacing(1.5),
            color: theme.palette.grey[700],
            borderTopLeftRadius: 25,
            borderTopRightRadius: 25,
          }
        : {
            position: 'fixed',
            top: 0,
            left: 0,
            right: 0,
            zIndex: 2,
            backgroundColor: theme.palette.common.white,
            justifyContent: 'space-between',
            flexDirection: 'row',
            display: 'flex',
            alignItems: 'center',
            paddingRight: theme.spacing(2),
            paddingLeft: theme.spacing(2),
            paddingTop: theme.spacing(1.5),
            paddingBottom: theme.spacing(1.5),
            color: theme.palette.grey[700],
          },
    headerIcon: {
      paddingRight: theme.spacing(1),
    },
    headerText: {
      fontWeight: 'medium',
      fontSize: 14,
    },
  })
);
