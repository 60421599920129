import React, { useEffect } from 'react';
import clsx from 'clsx';
import { Box, IconButton } from '@material-ui/core';
import { useHistory, useParams } from 'react-router-dom';
import { ArrowBack } from '@material-ui/icons';
import { DriverDetails } from '../../layout/DriverDetails';
import { useStyles } from './DriverPage.styles';
import useViewportHeight from '../../../hooks/useViewportHeight/useViewportHeight';
import { LeftMenuDrawer } from '../../navigation/LeftMenuDrawer/LeftMenuDrawer';
import { SearchMenuBar } from '../../navigation/SearchMenuBar/SearchMenuBar';
import { Map } from '../../layout/Map';
import { useDriverPageMap, useDriverDetails } from './DriverPage.hooks';
import useIsDesktop from '../../../hooks/useIsDesktop/useIsDesktop';
import { DesktopLayout } from '../../layout/DesktopLayout/DesktopLayout';

export function DriverPage() {
  const classes = useStyles();
  const params = useParams();

  const { selectedDriver, selectedDriverLoading, refetch } = useDriverDetails({
    id: params.id,
  });

  const history = useHistory();
  const vh = useViewportHeight();
  const isDesktop = useIsDesktop();

  const { mapProps, onFirstFetch } = useDriverPageMap({
    selectedDriver,
    isDesktop,
    vh,
    classes,
  });

  useEffect(() => {
    const firstFetch = async () => {
      const result = await refetch();
      onFirstFetch(result);
    };
    firstFetch();
  }, [onFirstFetch, refetch]);

  const MapComponentMounted = (
    <Map mapProps={mapProps} mapKey="driver-page-map" />
  );

  const DriverDetailsMounted = (
    <DriverDetails
      details={selectedDriver}
      isLoading={selectedDriverLoading}
      onRefresh={refetch}
    />
  );

  return isDesktop ? (
    <main className={clsx(classes.content)}>
      <DesktopLayout
        HeaderComponent={
          <Box className={classes.desktopSearchBar}>
            <SearchMenuBar goBack />
          </Box>
        }
        MapComponent={MapComponentMounted}
      >
        <Box className={classes.desktopDetailsContainer}>
          {DriverDetailsMounted}
        </Box>
      </DesktopLayout>
    </main>
  ) : (
    <main className={clsx(classes.content)}>
      <LeftMenuDrawer />
      <Box className={classes.header}>
        <IconButton
          data-test-id="tO5H8pNfp2YzBCKIiWDzV"
          aria-label="go back"
          className={classes.backButton}
          onClick={() => history.goBack()}
        >
          <ArrowBack />
        </IconButton>
      </Box>
      <Box height="30vh" width="100%" position="fixed">
        {MapComponentMounted}
      </Box>
      <Box
        position="fixed"
        height="70vh"
        top="30vh"
        width="100%"
        className={classes.detailsContainer}
      >
        {DriverDetailsMounted}
      </Box>
    </main>
  );
}
