import { useCallback, useRef, useState } from 'react';

export default function useHover({
  mouseEnterDelayMS = 0,
  mouseLeaveDelayMS = 0,
} = {}) {
  const [isHovering, setIsHovering] = useState(false);
  const mouseEnterTimer = useRef(null);
  const mouseOutTimer = useRef(null);

  const onMouseEnter = useCallback(() => {
    clearTimeout(mouseEnterTimer.current);
    clearTimeout(mouseOutTimer.current);
    mouseEnterTimer.current = setTimeout(
      () => setIsHovering(true),
      mouseEnterDelayMS
    );
  }, [mouseEnterDelayMS]);
  const onMouseLeave = useCallback(() => {
    clearTimeout(mouseEnterTimer.current);
    mouseOutTimer.current = setTimeout(
      () => setIsHovering(false),
      mouseLeaveDelayMS
    );
  }, [mouseLeaveDelayMS]);
  return [isHovering, onMouseEnter, onMouseLeave];
}
