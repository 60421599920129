export const _centerMapOnBounds = ({
  markers,
  shouldZoom,
  mapRef,
  groupRef,
}) => {
  if (markers && markers?.length > 0) {
    const map = mapRef?.current?.leafletElement;
    const group = groupRef?.current?.leafletElement;
    if (map && group) {
      if (shouldZoom) {
        map.fitBounds(group.getBounds());
      } else {
        map.panTo(group.getBounds().getCenter());
      }
    }
  }
};
