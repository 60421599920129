import React from 'react';

import { HeaderMenuBar } from '../../navigation/HeaderMenuBar';
import { LeftMenuDrawer } from '../../navigation/LeftMenuDrawer';

export function Header() {
  return (
    <>
      <HeaderMenuBar />
      <LeftMenuDrawer />
    </>
  );
}
