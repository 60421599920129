import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
  container: {
    borderTopLeftRadius: theme.spacing(2),
    borderTopRightRadius: theme.spacing(2),
  },
  content: {
    padding: theme.spacing(2),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  metaContainer: {
    display: 'flex',
    flexDirection: 'column',
  },
  spacing: {
    marginLeft: theme.spacing(3),
  },
  metaTitle: {
    fontSize: 12,
  },
  metaValue: {
    fontSize: 16,
    fontWeight: 'bold',
  },
  leftContainer: {
    display: 'flex',
    alignItems: 'center',
    textAlign: 'left',
  },
  rightContainer: {
    display: 'flex',
    alignItems: 'center',
    textAlign: 'right',
  },
}));
