import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  ListItemSecondaryAction,
  Typography,
  ListItem,
  ListItemText,
  ListItemAvatar,
  Avatar,
} from '@material-ui/core';
import { ChevronRight as ChevronRightIcon } from '@material-ui/icons';
import Skeleton from '@material-ui/lab/Skeleton';
import { useStyles } from './DriverItem.styles';
import { getInitials } from '../../../utils/StringUtils';

export function DriverItemSkeleton() {
  const classes = useStyles();
  return (
    <ListItem
      className={classes.listItem}
      button
      aria-label="driver item skeleton"
    >
      <ListItemAvatar>
        <Skeleton variant="circle">
          <Avatar className={classes.driverAvatar} />
        </Skeleton>
      </ListItemAvatar>
      <ListItemText
        disableTypography
        primary={
          <Typography className={classes.primaryText}>
            <Skeleton />
          </Typography>
        }
        secondary={
          <Box className={classes.secondaryContainer}>
            <Typography className={classes.activeText}>
              <Skeleton width={20} />
            </Typography>
            <div className={classes.divider} />
            <Typography className={classes.doneText}>
              <Skeleton width={20} />
            </Typography>
            <div className={classes.divider} />
            <Typography className={classes.earnedText}>
              <Skeleton width={20} />
            </Typography>
          </Box>
        }
      />
      <ListItemSecondaryAction>
        <ChevronRightIcon className={classes.rightIcon} />
      </ListItemSecondaryAction>
    </ListItem>
  );
}

export const DriverItem = React.memo(
  ({
    type = 'default',
    firstName,
    lastName,
    status,
    active,
    orders,
    earned,
    onClick,
    hideAvatar,
    hideAction,
    hideRipple,
    disableGutters,
  }) => {
    const classes = useStyles({ status });
    const initials = useMemo(
      () => getInitials(`${firstName} ${lastName}`),
      [firstName, lastName]
    );

    const Active = useMemo(() => {
      switch (type) {
        case 'order-history':
          return null;
        default:
          return (
            <>
              <Typography className={classes.activeText}>
                <span className={classes.boldText}>{active}</span> Active
              </Typography>
              <div className={classes.divider} />
            </>
          );
      }
    }, [type, active, classes]);

    return (
      <ListItem
        data-test-id="5l28TlUzrDajglyKXsw5t"
        disableGutters={disableGutters}
        className={classes.listItem}
        button
        onClick={onClick}
        aria-label="driver item"
        disableRipple={hideRipple}
      >
        {!hideAvatar && (
          <ListItemAvatar>
            <Avatar className={classes.driverAvatar}>{initials}</Avatar>
          </ListItemAvatar>
        )}
        <ListItemText
          disableTypography
          primary={
            <Typography className={classes.primaryText}>
              {firstName} {lastName}
            </Typography>
          }
          secondary={
            <Box className={classes.secondaryContainer}>
              {Active}
              <Typography className={classes.ordersText}>
                <span className={classes.boldText}>{orders}</span> Orders
              </Typography>
              <div className={classes.divider} />
              <Typography className={classes.earnedText}>
                <span className={classes.boldText}>${earned}</span> Earned
              </Typography>
            </Box>
          }
        />
        {!hideAction && (
          <ListItemSecondaryAction>
            <ChevronRightIcon className={classes.rightIcon} />
          </ListItemSecondaryAction>
        )}
      </ListItem>
    );
  }
);
DriverItem.propTypes = {
  type: PropTypes.oneOf(['default', 'order-history']),
  firstName: PropTypes.string,
  lastName: PropTypes.string,
  status: PropTypes.oneOf(['busy', 'free', 'soon']),
  active: PropTypes.number,
  orders: PropTypes.number,
  earned: PropTypes.number,
  onClick: PropTypes.func,
  hideAvatar: PropTypes.bool,
  hideAction: PropTypes.bool,
  hideRipple: PropTypes.bool,
  disableGutters: PropTypes.bool,
};
