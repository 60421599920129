import { useCallback, useEffect, useMemo } from 'react';
import { useQuery } from 'react-query';
import { useMapContext } from '../../../context/MapContext';
import { getSelectedDriver } from '../../../services/DeliveryOperationsPortalService';

const buildMarkers = (selectedDriver) => {
  if (selectedDriver)
    return [
      {
        id: selectedDriver.id,
        status: selectedDriver.status,
        text: `${selectedDriver?.firstName} ${selectedDriver?.lastName}`,
        latitude: selectedDriver?.location?.latitude,
        longitude: selectedDriver?.location?.longitude,
      },
    ];
  return [];
};

export const useDriverPageMap = ({
  selectedDriver,
  isDesktop,
  vh,
  classes,
}) => {
  const markers = useMemo(() => buildMarkers(selectedDriver), [selectedDriver]);

  const { updateMarkers } = useMapContext();

  const onFirstFetch = useCallback(
    (result) => {
      updateMarkers(buildMarkers(result), {
        centerAfterUpdate: true,
        shouldZoom: true,
      });
    },
    [updateMarkers]
  );

  useEffect(() => {
    updateMarkers(markers, { centerAfterUpdate: true, shouldZoom: false });
  }, [markers, updateMarkers]);

  const mapProps = useMemo(
    () =>
      isDesktop
        ? {
            className: classes.mapContainerDesktop,
          }
        : {
            className: classes.mapContainer,
            style: { height: 30 * vh },
          },
    [isDesktop, vh, classes.mapContainer, classes.mapContainerDesktop]
  );

  return {
    markers,
    mapProps,
    onFirstFetch,
  };
};

export const useDriverDetails = ({ id }) => {
  const {
    data: selectedDriver,
    isFetching: selectedDriverLoading,
    refetch,
  } = useQuery(['driver-details', id], (key, id) => getSelectedDriver(id), {
    enabled: false,
    refetchInterval: 15000,
    refetchIntervalInBackground: true,
  });

  return {
    selectedDriver,
    selectedDriverLoading,
    refetch,
  };
};
