/* eslint-disable no-unused-expressions */
import 'react-leaflet-markercluster/dist/styles.min.css';
import React, { useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import { Map as LeafletMap, TileLayer } from 'react-leaflet';
import Marker from 'react-leaflet-enhanced-marker';
import MarkerClusterGroup from 'react-leaflet-markercluster';
import useInterval from '@use-it/interval';
import { DriverMarker } from '../../units/DriverMarker/DriverMarker';
import { useMapHovering } from './Map.hooks';
import { useHoverContext } from '../../../context/HoverContext';
import { useMapContext } from '../../../context/MapContext';

export const shouldBeDimmed = (id, hoverId) => hoverId && hoverId !== id;

function MarkerItem({ id, onClick, status, text, latitude, longitude }) {
  const { hoverId } = useHoverContext();
  const isDimmed = useMemo(() => shouldBeDimmed(id, hoverId), [id, hoverId]);

  return (
    <Marker
      data-test-id="OKM7LHz615XhM-WhPIBuZ"
      onClick={onClick}
      key={id}
      icon={<DriverMarker status={status} text={text} isDimmed={isDimmed} />}
      position={[latitude, longitude]}
    />
  );
}

MarkerItem.propTypes = {
  status: PropTypes.string,
  id: PropTypes.number,
  text: PropTypes.string,
  latitude: PropTypes.number,
  longitude: PropTypes.number,
  onClick: PropTypes.func,
};

const NonMemoMarkersList = ({ markers }) =>
  markers?.map((markerItem) => (
    <MarkerItem key={markerItem.id} {...markerItem} />
  ));

const MarkersList = React.memo(NonMemoMarkersList);

MarkersList.propTypes = {
  markers: PropTypes.arrayOf(
    PropTypes.shape({
      status: PropTypes.string,
      id: PropTypes.number,
      text: PropTypes.string,
      latitude: PropTypes.number,
      longitude: PropTypes.number,
      onClick: PropTypes.func,
    })
  ),
};

export const invalidateSize = (map) =>
  setTimeout(() => {
    map?.invalidateSize();
  }, 400);
export function Map({ mapProps, mapKey, groupMarkers }) {
  const { hoverId } = useHoverContext();

  const { mapRef, groupRef, initialPosition, setInitialPosition, markers } =
    useMapContext();

  useMapHovering({
    mapRef,
    hoverId,
  });

  useEffect(() => {
    const timeout = invalidateSize(mapRef?.current?.leafletElement);
    return () => clearTimeout(timeout);
  }, [mapRef]);

  useInterval(() => {
    const map = mapRef?.current?.leafletElement;
    if (map)
      setInitialPosition({ center: map.getCenter(), zoom: map.getZoom() });
  }, 2000);

  const firstPosition = useMemo(() => initialPosition, []); // eslint-disable-line

  return (
    <LeafletMap
      key={mapKey}
      preferCanvas
      center={firstPosition.center}
      zoom={firstPosition.zoom}
      ref={mapRef}
      zoomControl={false}
      {...mapProps}
    >
      <TileLayer
        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
        attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
      />

      {groupMarkers ? (
        <MarkerClusterGroup ref={groupRef} maxClusterRadius={100}>
          <MarkersList markers={markers} />
        </MarkerClusterGroup>
      ) : (
        <MarkerClusterGroup
          ref={groupRef}
          maxClusterRadius={100}
          disableClusteringAtZoom
        >
          <MarkersList markers={markers} />
        </MarkerClusterGroup>
      )}
    </LeafletMap>
  );
}

Map.propTypes = {
  markers: PropTypes.arrayOf(
    PropTypes.shape({
      status: PropTypes.string,
      id: PropTypes.number,
      text: PropTypes.string,
      latitude: PropTypes.number,
      longitude: PropTypes.number,
      onClick: PropTypes.func,
    })
  ),
  mapProps: PropTypes.any,
  mapKey: PropTypes.string,
  groupMarkers: PropTypes.bool,
};

Map.defaultProps = {
  mapProps: {},
  groupMarkers: true,
};
