import React, { useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { NavLink as RouterLink, useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';

import Drawer from '@material-ui/core/Drawer';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import HistoryIcon from '@material-ui/icons/History';
import HomeIcon from '@material-ui/icons/Home';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import { Box } from '@material-ui/core';
import { logoutRequest } from '../../../redux/ducks/UserDucks';
import { USER_LOGOUT } from '../../../constants/auth';
import { useStyles, useDrawerLinkStyles } from './LeftMenuDrawer.styles';
import { setLeftMenuDrawerOpen } from '../../../redux/ducks/DrawerDucks';

export function DrawerLink({ to, className, ariaLabel, label, icon, onClick }) {
  const location = useLocation();
  const classes = useDrawerLinkStyles({ selected: to === location.pathname });
  const Icon = icon;
  return (
    <RouterLink
      data-test-id="kvbGuoH_vCCmM15cjtZL-"
      aria-label={ariaLabel}
      to={to}
      className={className}
      onClick={onClick}
    >
      <ListItem
        button
        selected={to === location.pathname}
        classes={{
          selected: classes.selectedDrawerItem,
        }}
      >
        {icon && (
          <ListItemIcon className={classes.listIcon}>
            <Icon />
          </ListItemIcon>
        )}
        <ListItemText primary={label} />
      </ListItem>
    </RouterLink>
  );
}

DrawerLink.propTypes = {
  to: PropTypes.string,
  className: PropTypes.string,
  label: PropTypes.string,
  ariaLabel: PropTypes.string,
  icon: PropTypes.any,
  onClick: PropTypes.func,
};

export function LeftMenuDrawer() {
  const classes = useStyles();

  const leftMenuDrawerOpen = useSelector(
    (state) => state.drawer.leftMenuDrawerOpen
  );
  const user = useSelector((state) => state.user.user);
  const userFullName = useMemo(
    () => user?.firstname && `${user?.firstname} ${user?.lastname}`,
    [user]
  );

  const dispatch = useDispatch();

  const closeOnClick = () => {
    dispatch(setLeftMenuDrawerOpen(false));
  };

  const signOut = async () => {
    dispatch(logoutRequest(USER_LOGOUT));
  };

  return (
    <Drawer
      className={classes.drawer}
      anchor="left"
      aria-label="left menu drawer"
      onClose={closeOnClick}
      open={leftMenuDrawerOpen}
      classes={{
        paper: classes.drawerPaper,
      }}
    >
      <Box flex={1}>
        <List>
          <ListItem>
            <ListItemText
              classes={{
                primary: classes.primaryHeaderText,
                secondary: classes.primaryHeaderText,
              }}
              primary={user?.email}
              secondary={userFullName}
            />
          </ListItem>
        </List>
        <List>
          <DrawerLink
            data-test-id="UISH3qFHxSLW5z9msMJsp"
            to="/"
            className={classes.navlink}
            onClick={closeOnClick}
            label="Home"
            icon={HomeIcon}
          />
          <DrawerLink
            data-test-id="rAg1g86WB30C4tEBPkL1X"
            to="/history"
            className={classes.navlink}
            onClick={closeOnClick}
            label="Order History"
            icon={HistoryIcon}
          />
        </List>
      </Box>
      <Box>
        <List>
          <DrawerLink
            data-test-id="k23_Coxy8fJm5RkEIAi7N"
            to="/login"
            className={classes.navlink}
            onClick={() => {
              closeOnClick();
              signOut();
            }}
            label="Logout"
            icon={ExitToAppIcon}
            ariaLabel="signout button"
          />
          <DrawerLink
            data-test-id="78Yz-g7iAjoJkMesgHMvM"
            to="/privacy-policy"
            className={classes.navlink}
            onClick={closeOnClick}
            label="Privacy Policy"
          />
          <DrawerLink
            data-test-id="eC0FiL9g53GcGrAHHuw2Z"
            to="/terms-of-use"
            className={classes.navlink}
            onClick={closeOnClick}
            label="Terms of Use"
          />
          <DrawerLink
            data-test-id="PFXjjWVnaRbOPStGn87HB"
            to="/about"
            className={classes.navlink}
            onClick={closeOnClick}
            label="About"
          />
        </List>
      </Box>
    </Drawer>
  );
}
