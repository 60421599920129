const getInitials = (name) => {
  if (!name) return '';
  const splitNames = name.split(' ');
  return splitNames
    .map((word) => word[0])
    .slice(0, 2)
    .join('')
    .toUpperCase();
};
export default getInitials;
