import React from 'react';
import { useSelector, useDispatch } from 'react-redux';

import clsx from 'clsx';

import { Link as RouterLink } from 'react-router-dom';

import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import MenuIcon from '@material-ui/icons/Menu';
import Badge from '@material-ui/core/Badge';
import Typography from '@material-ui/core/Typography';
import AccountCircle from '@material-ui/icons/AccountCircle';
import NotificationsIcon from '@material-ui/icons/Notifications';
import MoreIcon from '@material-ui/icons/MoreVert';
import {
  setMoreMobileMenuOpen,
  setLeftMenuDrawerOpen,
} from '../../../redux/ducks/DrawerDucks';
import { useStyles } from './HeaderMenuBar.styles';
import useNotifications from '../../../hooks/useNotifications';

export function HeaderMenuBar() {
  const { enqueueSnackbar } = useNotifications();

  const classes = useStyles();

  const mobileMoreMenuOpen = useSelector(
    (state) => state.drawer.mobileMoreMenuOpen
  );
  const leftMenuDrawerOpen = useSelector(
    (state) => state.drawer.leftMenuDrawerOpen
  );

  const dispatch = useDispatch();

  const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = React.useState(null);

  const handleMobileMenuAction = (event) => {
    if (mobileMoreMenuOpen) {
      setMobileMoreAnchorEl(null);
      dispatch(setMoreMobileMenuOpen(false));
    } else {
      setMobileMoreAnchorEl(event.currentTarget);
      dispatch(setMoreMobileMenuOpen(true));
      dispatch(setLeftMenuDrawerOpen(false));
    }
  };

  const sendRandomNotification = () => {
    enqueueSnackbar({
      message: 'This is a success notification',
      options: {
        variant: 'success',
      },
    });
  };

  const mobileMenuId = 'primary-search-account-menu-mobile';
  const renderMobileMenu = (
    <Menu
      anchorEl={mobileMoreAnchorEl}
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      id={mobileMenuId}
      keepMounted
      transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      open={mobileMoreMenuOpen}
      onClose={handleMobileMenuAction}
    >
      <MenuItem
        data-test-id="dKFIYH84fnoErdFxZzd2P"
        aria-label="mobile notification item"
        onClick={(event) => {
          sendRandomNotification(event);
          handleMobileMenuAction(event);
        }}
      >
        <IconButton color="inherit">
          <Badge badgeContent={11} color="secondary">
            <NotificationsIcon />
          </Badge>
        </IconButton>
        <p>Notifications</p>
      </MenuItem>
      <RouterLink
        data-test-id="c5BJw8W8cz7jqJ9EKNGDf"
        to="/profile"
        className={classes.navlink}
        onClick={handleMobileMenuAction}
      >
        <MenuItem>
          <IconButton aria-label="account of current user" color="inherit">
            <AccountCircle />
          </IconButton>
          <p>Profile</p>
        </MenuItem>
      </RouterLink>
    </Menu>
  );

  return (
    <>
      <AppBar
        position="static"
        className={clsx(classes.appBar, {
          [classes.appBarShift]: leftMenuDrawerOpen,
        })}
      >
        <Toolbar>
          <IconButton
            data-test-id="9ij47ibqYa_-IvobIxUme"
            color="inherit"
            aria-label="open drawer"
            onClick={() => {
              dispatch(setLeftMenuDrawerOpen(true));
            }}
            edge="start"
            className={clsx(
              classes.menuButton,
              leftMenuDrawerOpen && [classes.hide, { hidden: true }]
            )}
          >
            <MenuIcon />
          </IconButton>
          <Typography variant="h6" aria-label="section label" noWrap>
            Dashboard
          </Typography>
          <div className={classes.grow} />
          <div className={classes.sectionDesktop}>
            <IconButton
              data-test-id="aExsAQ10gqYsQRD86vHQv"
              aria-label="show 17 new notifications"
              color="inherit"
              onClick={sendRandomNotification}
            >
              <Badge badgeContent={17} color="secondary">
                <NotificationsIcon />
              </Badge>
            </IconButton>
            <RouterLink to="/profile" className={classes.navlink}>
              <IconButton
                edge="end"
                aria-label="account of current user"
                color="inherit"
              >
                <AccountCircle />
              </IconButton>
            </RouterLink>
          </div>
          <div className={classes.sectionMobile}>
            <IconButton
              data-test-id="VYuB30BGJYIxFxp6t9TG9"
              aria-label="show more"
              aria-controls={mobileMenuId}
              aria-haspopup="true"
              onClick={handleMobileMenuAction}
              color="inherit"
            >
              <MoreIcon />
            </IconButton>
          </div>
        </Toolbar>
      </AppBar>
      {renderMobileMenu}
    </>
  );
}
