import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  contents: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  logo: {
    width: 48,
    height: 48,
    marginBottom: theme.spacing(2),
  },
  form: {
    width: '100%', // Fix IE 11 issue.
  },
  input: {
    marginTop: theme.spacing(4),
  },
  submit: {
    width: '75%',
    marginTop: theme.spacing(4),
    marginLeft: 'auto',
    marginRight: 'auto',
  },
  forgotPassword: {
    marginTop: theme.spacing(3.5),
  },
}));
