import { getConfig } from '../config';
import { LOGIN_PATH } from '../constants/routes';
import { OAuthAuthentication } from '../lib/Authentication';
import {
  deauthorizeAccount,
  fetchRefreshToken,
  getMe,
  postLogin,
} from '../services/AccountService';
import history from './history';
import storage from './storage';

const defaultProps = {
  loginRequest: postLogin,
  getProfileRequest: getMe,
  refreshTokenRequest: fetchRefreshToken,
  deauthorizeRequest: deauthorizeAccount,
  navigateToLogin: () => history.push({ pathname: LOGIN_PATH }),
  roleType: getConfig('AUTH_ROLE_TYPE'),
  rolesAllowed: getConfig('AUTH_ROLES_ALLOWED'),
};

const Authentication = new OAuthAuthentication({ storage, ...defaultProps });

export default Authentication;
