export default class BaseAuthentication {
  constructor() {
    if (this.constructor === BaseAuthentication) {
      throw new TypeError(
        'Abstract class "BaseAuthentication" cannot be instantiated directly.'
      );
    }
  }

  login = async () => {
    throw new Error("Method 'login' must be implemented.");
  };

  getToken = async () => {
    throw new Error("Method 'getToken' must be implemented.");
  };

  getProfile = async () => {
    throw new Error("Method 'getProfile' must be implemented.");
  };

  _isTokenExpired = async () => {
    throw new Error("Method 'isTokenExpired' must be implemented.");
  };

  refreshToken = async () => {
    throw new Error("Method 'refreshToken' must be implemented.");
  };

  logout = async () => {
    throw new Error("Method 'logout' must be implemented.");
  };

  logoutCallback = async () => {
    throw new Error("Method 'logoutCallback' must be implemented.");
  };
}
