import { createSlice } from '@reduxjs/toolkit';

export const INITIAL_STATE = {
  center: [0, 0],
  zoom: 2,
};

const mapPositioningSlice = createSlice({
  name: 'mapPosition',
  initialState: {
    ...INITIAL_STATE,
  },

  reducers: {
    setCenter(state, action) {
      state.center = action.payload;
    },
    setZoom(state, action) {
      state.zoom = action.payload;
    },
    resetPosition() {
      return { ...INITIAL_STATE };
    },
  },
});

export const { setCenter, setZoom, resetPosition } =
  mapPositioningSlice.actions;

export default mapPositioningSlice.reducer;
