const { makeStyles } = require('@material-ui/core');

export const useStyles = makeStyles((theme) => ({
  content: {
    backgroundColor: theme.palette.grey[100],
    height: '100vh',
  },
  desktopColumn: {
    display: 'flex',
    flexDirection: 'column',
    flex: '0 0 408px',
    backgroundColor: theme.palette.grey[100],
    overflowY: 'scroll',
    overflowX: 'hidden',

    '&::-webkit-scrollbar': {
      width: '0.4em',
    },
    '&::-webkit-scrollbar-track': {
      boxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
      webkitBoxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: 'rgba(0,0,0,.1)',
    },
  },
  mapContainerDesktop: {
    height: '100vh',
    width: '100%',
    overflow: 'hidden',
    position: 'relative',
  },
  desktopHeader: {
    padding: theme.spacing(7, 1),
    position: 'relative',
  },
  desktopSearchBar: {
    position: 'fixed',
    top: theme.spacing(4),
    width: `calc(408px - ${theme.spacing(2)}px)`,
    zIndex: theme.zIndex.appBar,
  },
  desktopFooterSummary: {
    position: 'fixed',
    bottom: 0,
    width: `calc(408px - ${theme.spacing(1)}px)`,
    zIndex: theme.zIndex.appBar,
  },
  desktopDetailsContainer: {
    background: theme.palette.common.white,
    flex: 1,
    borderTopLeftRadius: 25,
    borderTopRightRadius: 25,
  },
  header: {
    padding: theme.spacing(1),
    position: 'fixed',
    width: '100%',
    zIndex: theme.zIndex.appBar,
  },
  footer: {
    position: 'fixed',
    width: '100%',
    bottom: 0,
  },
  body: {
    marginTop: 80,
    marginBottom: 100,
    padding: theme.spacing(1),
    position: 'relative',
  },
  rootcontainer: {
    flexGrow: 1,
    height: '100%',
  },
}));
