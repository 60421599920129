const { makeStyles, fade } = require('@material-ui/core');

export const useStyles = makeStyles((theme) => ({
  header: {
    display: 'flex',
    flexDirection: 'column',
    padding: theme.spacing(1),
    position: 'fixed',
    left: 0,
    right: 0,
    top: 0,
    zIndex: 2,
  },
  rootcontainer: {
    flexGrow: 1,
    height: '100%',
  },
  search: {
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: fade(theme.palette.common.white, 1),
    width: '100%',
    display: 'flex',
  },
  drawerIcon: {
    padding: theme.spacing(0, 2),
  },
  inputRoot: {
    color: 'inherit',
  },
  inputInput: {
    padding: theme.spacing(1, 1, 1, 0),
    transition: theme.transitions.create('width'),
    width: '100%',
  },
  mapContainer: {
    position: 'absolute',
    height: '80%',
    left: 0,
    right: 0,
    top: 0,
    zIndex: 0,
    width: '100%',
    overflow: 'hidden',
  },
  mapContainerDesktop: {
    height: '100vh',
    width: '100%',
    overflow: 'hidden',
    position: 'relative',
  },
  desktopFilterContainer: {
    position: 'absolute',
    zIndex: 500,
    left: theme.spacing(2),
  },
  desktopColumn: {
    display: 'flex',
    flexDirection: 'column',
    flex: '0 0 408px',
    backgroundColor: theme.palette.grey[100],
    overflowY: 'auto',
    overflowX: 'hidden',

    '&::-webkit-scrollbar': {
      width: '0.4em',
    },
    '&::-webkit-scrollbar-track': {
      boxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
      webkitBoxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: 'rgba(0,0,0,.1)',
    },
  },
  desktopHeader: {
    padding: theme.spacing(7, 1),
    position: 'relative',
  },
  desktopSearchBar: {
    position: 'fixed',
    top: theme.spacing(4),
    width: `calc(408px - ${theme.spacing(2)}px)`,
    zIndex: theme.zIndex.appBar,
  },
  chipFilter: {
    background: theme.palette.common.white,
    fontSize: 14,
  },
  chipIcon: {
    fontSize: 12,
  },
  filtersContainer: {
    display: 'flex',
    overflowX: 'auto',
  },
  bottomSheet: {
    backgroundColor: theme.palette.common.white,
  },
}));
