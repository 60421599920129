import { makeStyles } from '@material-ui/core/styles';

const drawerWidth = 240;

export const useStyles = makeStyles((theme) => ({
  grow: {
    flexGrow: 1,
  },
  appBar: {
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: drawerWidth,
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  hide: {
    display: 'none',
  },
  sectionDesktop: {
    display: 'none',
    [theme.breakpoints.up('md')]: {
      display: 'flex',
    },
  },
  sectionMobile: {
    display: 'flex',
    [theme.breakpoints.up('md')]: {
      display: 'none',
    },
  },
  navlink: {
    textDecoration: 'none',
    color: 'inherit',
    '& hover': {
      textDecoration: 'none',
      color: 'inherit',
    },
    '& focus': {
      textDecoration: 'none',
      color: 'inherit',
    },
    '& link': {
      textDecoration: 'none',
      color: 'inherit',
    },
    '& visited': {
      textDecoration: 'none',
      color: 'inherit',
    },
    '& active': {
      textDecoration: 'none',
      color: 'inherit',
    },
  },
}));
