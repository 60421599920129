import { combineReducers } from 'redux';
import user from './UserDucks';
import drawer from './DrawerDucks';
import snackbar from './SnackbarDucks';
import mapPosition from './MapPositionDucks';

export default combineReducers({
  user,
  drawer,
  snackbar,
  mapPosition,
});
