import React from 'react';
import { CircularProgress, Button as MUIButton } from '@material-ui/core';
import PropTypes from 'prop-types';

function Button({ loading, children, ...props }) {
  return (
    <MUIButton data-testid="button-with-loading" {...props} disabled={loading}>
      {loading ? <CircularProgress size={24} /> : children}
    </MUIButton>
  );
}

Button.propTypes = {
  loading: PropTypes.bool,
  children: PropTypes.node,
};
Button.defaultProps = {
  loading: false,
  children: null,
};

export default Button;
