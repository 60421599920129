import React from 'react';
import PropTypes from 'prop-types';
import { Link, Grid, Typography, Box } from '@material-ui/core';

import * as yup from 'yup';
import Button from '../Button';
import Form from '../Form';
import Input from '../Input';
import logoImage from '../../../assets/images/logo.png';

import { useStyles } from './LoginForm.styles';

const FormSchema = yup.object().shape({
  email: yup.string().required('Email is required').email('Invalid Email'),
  password: yup.string().required('Password is required'),
});

export function LoginForm({ onSubmit, isLoading }) {
  const classes = useStyles();

  return (
    <Box>
      <Box className={classes.contents}>
        <img src={logoImage} alt="logo" className={classes.logo} />
        <Typography component="h1" variant="h5">
          Sign in
        </Typography>
        <Form
          schema={FormSchema}
          className={classes.form}
          noValidate
          onSubmit={onSubmit}
          aria-label="login form"
        >
          <Input
            className={classes.input}
            variant="filled"
            margin="normal"
            required
            fullWidth
            id="email"
            label="E-mail"
            name="email"
            placeholder="Type your email"
            autoComplete="email"
            autoFocus
            inputProps={{ 'data-testid': 'login:email-input' }}
          />
          <Input
            className={classes.input}
            variant="filled"
            margin="normal"
            required
            fullWidth
            name="password"
            label="Password"
            placeholder="Type your password"
            type="password"
            id="password"
            autoComplete="current-password"
            inputProps={{ 'data-testid': 'login:password-input' }}
          />
          <Box display="flex" justifyContent="center">
            <Button
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              className={classes.submit}
              loading={isLoading}
              data-testid="login:button"
              aria-label="sign in button"
            >
              Sign In
            </Button>
          </Box>

          <Grid
            className={classes.forgotPassword}
            container
            display="flex"
            justify="center"
          >
            <Grid item>
              <Link href="#" variant="body2">
                Forgot password?
              </Link>
            </Grid>
          </Grid>
        </Form>
      </Box>
    </Box>
  );
}

LoginForm.propTypes = {
  onSubmit: PropTypes.func,
  isLoading: PropTypes.bool,
  backendError: PropTypes.string,
  handleClose: PropTypes.func,
};
