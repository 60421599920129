import { takeEvery, call, put } from 'redux-saga/effects';
import {
  signInRequest,
  signInSuccess,
  error,
  meRequest,
  meSuccess,
  initialLoadRequest,
  initialLoadSuccess,
  initialLoadUnauthenticated,
  logoutRequest,
  logoutSuccess,
} from '../../ducks/UserDucks';
import history from '../../../utils/history';
import { ROOT_PATH } from '../../../constants/routes';
import Authentication from '../../../utils/authentication';
import { enqueueSnackbar } from '../../ducks/SnackbarDucks';

function* signIn(action) {
  try {
    const { email, password } = action.payload;
    yield call(Authentication.login, email, password);
    const profile = yield call(Authentication.getProfile);
    yield put(
      signInSuccess({
        user: profile,
      })
    );
    history.push(ROOT_PATH);
  } catch (err) {
    yield put(
      enqueueSnackbar({
        message: err.message,
        options: {
          variant: 'error',
        },
      })
    );
    yield put(error({}));
    yield call(Authentication.logout);
  }
}

function* fetchUser() {
  try {
    const profile = yield call(Authentication.getProfile);
    yield put(
      meSuccess({
        user: profile,
      })
    );
  } catch (err) {
    yield put(
      enqueueSnackbar({
        message: err.message,
        options: {
          variant: 'error',
        },
      })
    );
    yield put(error({ message: err.message }));

    yield call(Authentication.logout);
  }
}

function* signOut(action) {
  const message = yield call(Authentication.logout, action.payload);
  yield put(logoutSuccess());
  if (message) {
    yield put(enqueueSnackbar(message));
  }
}

function* initialUserLoad() {
  try {
    const token = yield call(Authentication.getToken);
    if (!token) return yield put(initialLoadUnauthenticated());
    const profile = yield call(Authentication.getProfile);
    yield put(
      initialLoadSuccess({
        user: profile,
      })
    );
  } catch (err) {
    yield put(error({ message: err.message }));
  }
}

export default function* watchUser() {
  yield takeEvery(signInRequest, signIn);
  yield takeEvery(logoutRequest, signOut);
  yield takeEvery(meRequest, fetchUser);
  yield takeEvery(initialLoadRequest, initialUserLoad);
}
