import { createStyles, fade, makeStyles } from '@material-ui/core/styles';

const drawerWidth = 240;

export const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  grow: {
    flexGrow: 1,
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  hide: {
    display: 'none',
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  drawerPaper: {
    width: drawerWidth,
  },
  drawerHeader: {
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    justifyContent: 'flex-end',
  },
  navlink: {
    textDecoration: 'none',
    color: 'inherit',
    '& hover': {
      textDecoration: 'none',
      color: 'inherit',
    },
    '& focus': {
      textDecoration: 'none',
      color: 'inherit',
    },
    '& link': {
      textDecoration: 'none',
      color: 'inherit',
    },
    '& visited': {
      textDecoration: 'none',
      color: 'inherit',
    },
    '& active': {
      textDecoration: 'none',
      color: 'inherit',
      background: 'blue',
    },
  },
  primaryHeaderText: {
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
  },
}));

export const useDrawerLinkStyles = makeStyles(
  createStyles((theme) => ({
    selectedDrawerItem: {
      '&&': {
        color: theme.palette.primary.main,
        backgroundColor: fade(theme.palette.primary.main, 0.04),
      },
    },
    listIcon: {
      color: ({ selected }) => selected && theme.palette.primary.main,
    },
  }))
);
