import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Grid from '@material-ui/core/Grid';
import Container from '@material-ui/core/Container';
import { Card, CardContent } from '@material-ui/core';
import { signInRequest } from '../../../redux/ducks/UserDucks';

import { LoginForm } from '../../input/LoginForm/LoginForm';
import { Footer } from '../../layout/Footer';
import { useStyles } from './LoginPage.styles';
import { ROOT_PATH } from '../../../constants/routes';
import history from '../../../utils/history';
import storage from '../../../utils/storage';
import useIsDesktop from '../../../hooks/useIsDesktop/useIsDesktop';

export function LoginPage() {
  const isDesktop = useIsDesktop();
  const classes = useStyles({ isDesktop });
  const dispatch = useDispatch();

  const isLoading = useSelector((state) => state.user.isLoading);
  const user = useSelector((state) => state.user.user);

  useEffect(() => {
    const checkToken = async () => {
      const isAuthTokenPresent = await storage.get('user');

      if (user && isAuthTokenPresent) {
        history.replace(history.location?.state?.from || ROOT_PATH);
      }
    };
    checkToken();
  }, [user]);

  const onSubmit = (data) => {
    dispatch(signInRequest(data));
  };

  return (
    <>
      <Container
        component="main"
        maxWidth="xs"
        className={classes.rootcontainer}
      >
        <Card className={classes.card} variant="outlined">
          <CardContent className={classes.cardContent}>
            <Grid
              container
              direction="column"
              justify="center"
              alignItems="stretch"
            >
              <Grid item xs={12}>
                <Grid container>
                  <Grid item lg>
                    <LoginForm onSubmit={onSubmit} isLoading={isLoading} />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </Container>
      <Footer />
    </>
  );
}
