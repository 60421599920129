import React from 'react';
import _ from 'lodash';

import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import Link from '@material-ui/core/Link';

import { makeStyles } from '@material-ui/core/styles';

function Copyright() {
  const appVersion = _.isNil(process.env.REACT_APP_VERSION)
    ? '0.0.0-local'
    : process.env.REACT_APP_VERSION;

  return (
    <Typography
      variant="body2"
      color="textSecondary"
      align="center"
      aria-label="footer"
    >
      v{appVersion}
      {' - '}
      {'Copyright © '}
      <Link color="inherit" href="https://eatzy.com/">
        Eatzy
      </Link>{' '}
      {new Date().getFullYear()}
    </Typography>
  );
}

const useStyles = makeStyles((theme) => ({
  footer: {
    padding: theme.spacing(3, 2),
    marginTop: 'auto',
  },
}));

export function Footer() {
  const classes = useStyles();

  return (
    <footer className={classes.footer}>
      <Container maxWidth="sm">
        <Copyright />
      </Container>
    </footer>
  );
}
