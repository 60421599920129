import { useCallback, useEffect, useState } from 'react';
import PeopleIcon from '@material-ui/icons/People';
import CarIcon from '@material-ui/icons/DirectionsCar';
import SmileIcon from '@material-ui/icons/Mood';
import ClockIcon from '@material-ui/icons/Timer';
import { useQuery } from 'react-query';
import _ from 'lodash';
import { useFiltersByKey } from '../useFilterByKey/useFilterByKey';
import { getActiveDrivers } from '../../services/DeliveryOperationsPortalService';
import { useMapContext } from '../../context/MapContext';
import history from '../../utils/history';

export const pushToDriver = (id) => () => history.push(`/drivers/${id}`);

const filterOptions = [
  {
    id: 'all',
    title: 'All Drivers',
    icon: PeopleIcon,
  },
  {
    id: 'busy',
    title: 'Busy',
    icon: CarIcon,
  },
  {
    id: 'free',
    title: 'Free',
    icon: SmileIcon,
  },
  {
    id: 'soon',
    title: 'Soon',
    icon: ClockIcon,
  },
];

const buildMarkers = (driverList) =>
  driverList?.map((driver) => ({
    id: driver.id,
    status: driver.status,
    text: `${driver?.firstName} ${driver?.lastName}`,
    latitude: driver?.location?.latitude,
    longitude: driver?.location?.longitude,
    onClick: pushToDriver(driver.id),
  }));

export const useActiveDriversRequest = () => {
  const [enabled, setEnabled] = useState(false);

  const {
    data: driverList,
    isFetching: driverListLoading,
    refetch,
  } = useQuery('active-drivers', getActiveDrivers, {
    enabled,
    staleTime: 0,
    refetchInterval: 30000,
    refetchIntervalInBackground: true,
  });
  const refetchAndEnable = useCallback(() => {
    if (!enabled) {
      setEnabled(true);
    }
    return refetch();
  }, [enabled, setEnabled, refetch]);

  return {
    driverList,
    driverListLoading,
    refetch: refetchAndEnable,
  };
};

export const useActiveDrivers = ({ driverList, refetch }) => {
  const {
    data: filteredDriverList,
    onChange: onFilterChange,
    filters,
  } = useFiltersByKey(driverList, { status: filterOptions[0].id });

  const [search, setSearch] = useState({ key: '', text: '' });
  const [cache, setCache] = useState([]);

  /**
   *
   * @param {KeyboardEvent} event
   */
  const onSearch = (payload) => {
    setSearch({
      key: payload.key,
      text: payload.text,
    });
  };

  const { updateMarkers } = useMapContext();

  const doRefresh = useCallback(async () => {
    const result = await refetch();
    updateMarkers(buildMarkers(result), {
      centerAfterUpdate: true,
      shouldZoom: true,
    });
    setCache(result);
  }, [updateMarkers, refetch]);

  useEffect(() => {
    doRefresh();
  }, [doRefresh]);

  const updateMarkersAndCenter = () => {
    if (filteredDriverList?.length > 0) {
      const newMarkers = buildMarkers(filteredDriverList);
      updateMarkers(newMarkers, { centerAfterUpdate: true, shouldZoom: true });
    }
  };

  const updateMarkersWithoutCentering = () => {
    if (filteredDriverList?.length > 0) {
      const newMarkers = buildMarkers(filteredDriverList);
      updateMarkers(newMarkers, { centerAfterUpdate: false });
    }
  };

  const updateMarkersBySearchText = () => {
    const searchText = search.text;
    if (_.isEmpty(searchText)) {
      doRefresh();
      return;
    }
    const formattedSearchText = searchText.toLowerCase();
    const newFilteredDriverList = filteredDriverList
      .map((value) => ({ ...value }))
      .filter(
        (value) =>
          value.firstName.toLowerCase().indexOf(formattedSearchText) !== -1 ||
          value.lastName.toLowerCase().indexOf(formattedSearchText) !== -1
      );
    setCache(newFilteredDriverList);
    const newMarkers = buildMarkers(newFilteredDriverList);
    updateMarkers(newMarkers, { centerAfterUpdate: false });
  };

  useEffect(() => {
    updateMarkersAndCenter();
    // eslint-disable-next-line
  }, [filters]);

  useEffect(() => {
    updateMarkersWithoutCentering();
    setCache(filteredDriverList);
    // eslint-disable-next-line
  }, [filteredDriverList]);

  useEffect(() => {
    updateMarkersBySearchText();
    // eslint-disable-next-line
  }, [search]);

  return {
    filteredDriverList: cache || filteredDriverList,
    filterOptions,
    onFilterChange,
    filters,
    refetch: doRefresh,

    onSearch,
  };
};
