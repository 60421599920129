import { createSlice } from '@reduxjs/toolkit';

export const INITIAL_STATE = {
  leftMenuDrawerOpen: false,
  mobileMoreMenuOpen: false,
};

const drawerSlice = createSlice({
  name: 'drawer',
  initialState: {
    ...INITIAL_STATE,
  },
  reducers: {
    setLeftMenuDrawerOpen: (state, action) => {
      state.leftMenuDrawerOpen = action.payload;
    },
    setMoreMobileMenuOpen: (state, action) => {
      state.mobileMoreMenuOpen = action.payload;
    },
  },
});

export const { setLeftMenuDrawerOpen, setMoreMobileMenuOpen } =
  drawerSlice.actions;

export default drawerSlice.reducer;
