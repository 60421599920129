import React from 'react';
import { useSelector, useDispatch } from 'react-redux';

import clsx from 'clsx';
import PropTypes from 'prop-types';

import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import { InputBase, Typography } from '@material-ui/core';
import { ArrowBack } from '@material-ui/icons';
import { useStyles } from './SearchMenuBar.styles';
import history from '../../../utils/history';
import { setLeftMenuDrawerOpen } from '../../../redux/ducks/DrawerDucks';

export function SearchMenuBar({ goBack, title, onSearch }) {
  const classes = useStyles();

  const leftMenuDrawerOpen = useSelector(
    (state) => state.drawer.leftMenuDrawerOpen
  );

  const dispatch = useDispatch();

  const handleToolbarButton = () => {
    if (goBack) {
      history.goBack();
    } else {
      dispatch(setLeftMenuDrawerOpen(true));
    }
  };

  /**
   *
   * @param {KeyboardEvent} event
   */
  const handleInputKeyUp = (event) => {
    onSearch({
      key: event.key,
      text: event.target.value,
    });
  };

  return (
    <AppBar elevation={2} color="default" position="static">
      <Toolbar style={{ background: 'white' }}>
        <IconButton
          data-test-id="hGHp5AZmFnTGNVodvl8lL"
          color="inherit"
          aria-label="toolbar button"
          onClick={handleToolbarButton}
          edge="start"
          className={clsx(
            classes.menuButton,
            leftMenuDrawerOpen && [classes.hide, { hidden: true }]
          )}
        >
          {goBack ? <ArrowBack /> : <MenuIcon />}
        </IconButton>

        {title ? (
          <Typography variant="subtitle1">{title}</Typography>
        ) : (
          <InputBase
            placeholder="Search your drivers"
            classes={{
              root: classes.inputRoot,
              input: classes.inputInput,
            }}
            inputProps={{ 'aria-label': 'search' }}
            onKeyUp={handleInputKeyUp}
          />
        )}
        <div className={classes.grow} />
      </Toolbar>
    </AppBar>
  );
}

SearchMenuBar.propTypes = {
  goBack: PropTypes.bool,
  title: PropTypes.string,
  onSearch: PropTypes.func,
};
SearchMenuBar.defaultProps = {
  goBack: false,
  title: '',
};
