import React, { useMemo } from 'react';
import { Box } from '@material-ui/core';
import PropTypes from 'prop-types';
import ChipFilterList from '../ChipFilterList/ChipFilterList';
import { useStyles } from './ActiveDriversMap.styles';
import { Map } from '../Map';
import useIsDesktop from '../../../hooks/useIsDesktop/useIsDesktop';

export default function ActiveDriversMap({
  filterOptions,
  onFilterChange,
  filters,
  groupMarkers,
}) {
  const classes = useStyles();
  const isDesktop = useIsDesktop();

  const mapProps = useMemo(
    () =>
      isDesktop
        ? {
            className: classes.mapContainerDesktop,
          }
        : {
            className: classes.mapContainer,
          },
    [isDesktop, classes.mapContainerDesktop, classes.mapContainer]
  );
  return (
    <>
      {isDesktop && (
        <Box className={classes.desktopFilterContainer}>
          <ChipFilterList
            name="status"
            value={filters.status}
            onChange={onFilterChange}
            options={filterOptions}
          />
        </Box>
      )}

      <Map
        mapKey="active-drivers-map"
        ariaLabel="active drivers map"
        mapProps={mapProps}
        groupMarkers={groupMarkers}
      />
    </>
  );
}

ActiveDriversMap.propTypes = {
  filterOptions: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
      title: PropTypes.string.isRequired,
      icon: PropTypes.any.isRequired,
    }).isRequired
  ),
  onFilterChange: PropTypes.func,

  filters: PropTypes.objectOf(PropTypes.string),

  groupMarkers: PropTypes.bool,
};

ActiveDriversMap.defaultProps = {
  groupMarkers: true,
};
