import { useCallback } from 'react';

export const computeCustomSelection = (
  currentValue,
  option,
  isMultiple,
  enableUnselect
) => {
  const stringOption = String(option);
  if (isMultiple) {
    const selectedOptions = currentValue || [];
    const finalResult = selectedOptions.includes(stringOption)
      ? selectedOptions.filter((item) => item !== stringOption)
      : [...selectedOptions, stringOption];
    return finalResult;
  }
  const selectedOption = currentValue || null;
  if (selectedOption === stringOption && enableUnselect) {
    return null;
  }
  return stringOption;
};

export const hasSameElements = (array1, array2) =>
  array1.sort().join(',') === array2.sort().join(',');

export const computeCustomSelectAll = (currentValue, allOptions) => {
  if (hasSameElements(currentValue, allOptions)) {
    return [];
  }
  return allOptions;
};

const useSelectFields = ({
  onChange,
  value,
  name,
  isMultiple,
  enableUnselect = true,
  enableSelectAll,
}) => {
  const isSelected = useCallback(
    (optionId) =>
      isMultiple
        ? value?.includes(String(optionId))
        : value === String(optionId),
    [isMultiple, value]
  );

  const areAllSelected = useCallback(
    (options) => hasSameElements(value, options),
    [value]
  );

  const handleSelection = useCallback(
    (event, optionId) => {
      if (event) {
        event.stopPropagation();
        event.preventDefault();
      }
      const computedValue = computeCustomSelection(
        value,
        optionId,
        isMultiple,
        enableUnselect
      );
      onChange({
        target: {
          name,
          value: computedValue,
        },
      });
    },
    [onChange, value, isMultiple, name, enableUnselect]
  );

  const handleSelectAll = useCallback(
    (event, options) => {
      if (event) {
        event.stopPropagation();
        event.preventDefault();
      }

      if (enableSelectAll && !isMultiple)
        throw new Error(
          'Select all feature can only work if `isMultiple` === true'
        );

      onChange({
        target: {
          name,
          value: computeCustomSelectAll(value, options),
        },
      });
    },
    [enableSelectAll, value, isMultiple, name, onChange]
  );

  if (enableSelectAll) {
    return { isSelected, handleSelection, areAllSelected, handleSelectAll };
  }
  return { isSelected, handleSelection };
};

export default useSelectFields;
